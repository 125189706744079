import { ACTION_POLICY_CONTROL_NO_SUCCESS, ACTION_POLICY_DOCUMENT_HISTORY_SUCCESS, ACTION_POLICY_TYPE_SUCCESS, ACTION_UPLOAD_POLICY_DOCUMENT_SUCCESS } from "./action";

import createReducer from "../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const UploadPolicyDocumentReducer = createReducer(INITIAL_STATE, {
    [ACTION_POLICY_CONTROL_NO_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POLICY_CONTROL_NO_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_POLICY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POLICY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_POLICY_DOCUMENT_HISTORY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_POLICY_DOCUMENT_HISTORY_SUCCESS,
                loading: false,
            }
        );
    },
   
    [ACTION_UPLOAD_POLICY_DOCUMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_UPLOAD_POLICY_DOCUMENT_SUCCESS,
                loading: false,
            }
        );
    }
});
export default UploadPolicyDocumentReducer;