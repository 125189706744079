import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_POLICY_CONTROL_NO, API_POLICY_DOCUMENT_HISTORY, API_POLICY_TYPE, API_UPLOAD_POLICY_DOCUMENT } from '../../../../../api/constants';

export const ACTION_POLICY_CONTROL_NO_LOADING = 'ACTION_POLICY_CONTROL_NO_LOADING';
export const ACTION_POLICY_CONTROL_NO_SUCCESS = 'ACTION_POLICY_CONTROL_NO_SUCCESS';

export const ACTION_POLICY_TYPE_LOADING = 'ACTION_POLICY_TYPE_LOADING';
export const ACTION_POLICY_TYPE_SUCCESS = 'ACTION_POLICY_TYPE_SUCCESS';

export const ACTION_UPLOAD_POLICY_DOCUMENT_LOADING = 'ACTION_UPLOAD_POLICY_DOCUMENT_LOADING';
export const ACTION_UPLOAD_POLICY_DOCUMENT_SUCCESS = 'ACTION_UPLOAD_POLICY_DOCUMENT_SUCCESS';

export const ACTION_POLICY_DOCUMENT_HISTORY_LOADING = 'ACTION_POLICY_DOCUMENT_HISTORY_LOADING';
export const ACTION_POLICY_DOCUMENT_HISTORY_SUCCESS = 'ACTION_POLICY_DOCUMENT_HISTORY_SUCCESS';


export function actionControlPolicy(params) {
    return (dispatch, getState) =>
        Api.post(API_POLICY_CONTROL_NO, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_POLICY_CONTROL_NO_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_POLICY_CONTROL_NO_LOADING, { loading: false, data }));
        });
}

export function actionPolicyHistory() {
    return (dispatch, getState) =>
        Api.get(API_POLICY_DOCUMENT_HISTORY).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_POLICY_DOCUMENT_HISTORY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_POLICY_DOCUMENT_HISTORY_LOADING, { loading: false, data }));
        });
}

export function actionPolicyType(control_no) {
    return (dispatch, getState) => {
        dispatch(loading(ACTION_POLICY_TYPE_LOADING, { loading: true })); // Start loading state
        console.log("my control no", control_no)

        Api.post(API_POLICY_TYPE, control_no)  // Ensure control_no is included
            .then((res) => {
                dispatch(fetchSuccess(ACTION_POLICY_TYPE_SUCCESS, { data: res }));
            })
            .catch((reason) => {
                dispatch(loading(ACTION_POLICY_TYPE_LOADING, {
                    loading: false,
                    data: { message: reason?.message || "Error fetching policy type." }
                }));
            });
    };
}


export function actionUploadPolicyDoc(params, files) {
    return (dispatch, getState) =>

        Api.multipart(API_UPLOAD_POLICY_DOCUMENT, params, files)
            .then((res) => {
                console.log("API Response:", res);
                const data = res;
                dispatch(fetchSuccess(ACTION_UPLOAD_POLICY_DOCUMENT_SUCCESS, { data }));
            })
            .catch((reason) => {
                console.error("Error uploading document:", reason);
                // Handle any errors here
            });
};


