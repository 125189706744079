import { ACTION_EMPLOYEE_GET_REPORTING_SUCCESS, ACTION_EMPLOYEE_CHANGE_USER_STATUS_SUCCESS, ACTION_EMPLOYEE_LIST_SUCCESS, ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS, ACTION_EMPLOYEE_STATUS_SUCCESS, ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS, ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS } from "./action";

import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EmployeeInactiveUpdateReducer = createReducer(INITIAL_STATE, {
    [ACTION_EMPLOYEE_GET_REPORTING_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EMPLOYEE_GET_REPORTING_SUCCESS,
                loading: false,
            }
        );
    },

    [ACTION_EMPLOYEE_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EMPLOYEE_LIST_SUCCESS,
                loading: false,
            }
        );
    },

    [ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EMPLOYEE_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EMPLOYEE_STATUS_SUCCESS,
                loading: false,
            }
        );
    },

    [ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS](state, action) {
        return Object.assign({}, {
            ...state,
            data: action.payload.data,
            tag: ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS,
            loading: false
        });
    }

});

export default EmployeeInactiveUpdateReducer;