import React from 'react';
import { Route, Switch } from 'react-router';
import AddPolicyUploadDocument from './add';
import UploadDocumentList from './list';


const UploadPolicyDocument = (props) => {

    const {match} = props;

    return (
        <>
            <Switch>
                <Route path={`${match.url}/add`}  component={AddPolicyUploadDocument}/>
                <Route exact path={`${match.url}`}  component={UploadDocumentList}/>
            </Switch>
        </>
    )
}

export default UploadPolicyDocument