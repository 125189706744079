import React from 'react';
import { Route, Switch } from 'react-router';
import RolesRoute from './roles';
import PermissionsRoute from './permissions';
import BranchAddressesRoute from './branch_addresses';
import BranchCitiesRoute from './branch_cities';
import LeadSourceRoute from './lead_source';
import ContentRoute from './content';
import TeamMasterRoute from './team_master';
import CronDashboard from './cron_dashboard';
import ShiftTimingRoute from './shift_timing';
import NatureExpenses from './nature_of_expenses';
import TransportRoute from './transport';
import FinanceManagerRoute from './set_finance_manager';
import AppSettingsRoute from './app_setting';
import RMTransferRoute from './rm_transfer';
import OpportunityQuestionsRoute from './opportunity_questions';
import ProbabilityRoute from './probability';
import TravelCalendarSettingRoutes from './travel_calendar';
import LogoutAll from './logout';
import HRMSSettingsRoute from './hrms_setting';
import DeclarationRoute from './declaration';
import UploadPolicyDocument from '../upload_document_policy';

const PermissionRoute = (props) => {
    const { match } = props
    return (
        <Switch>
            <Route path={`${match.url}/roles`} component={RolesRoute} />
            <Route path={`${match.url}/permission`} component={PermissionsRoute} />
            <Route path={`${match.url}/branch-addresses`} component={BranchAddressesRoute} />
            <Route path={`${match.url}/branch-cities`} component={BranchCitiesRoute} />
            <Route path={`${match.url}/lead-sources`} component={LeadSourceRoute} />
            <Route path={`${match.url}/content`} component={ContentRoute} />
            <Route path={`${match.url}/declaration`} component={DeclarationRoute} />
            <Route path={`${match.url}/upload-policy-document`} component={UploadPolicyDocument} />
            <Route path={`${match.url}/team-master`} component={TeamMasterRoute} />
            <Route path={`${match.url}/cron-dashboard`} component={CronDashboard} />
            <Route path={`${match.url}/shift-timing`} component={ShiftTimingRoute} />
            <Route path={`${match.url}/nature_of_expenses`} component={NatureExpenses} />
            <Route path={`${match.url}/transport`} component={TransportRoute} />
            <Route path={`${match.url}/reimbursement-limit`} component={FinanceManagerRoute} />
            <Route path={`${match.url}/app-settings`} component={AppSettingsRoute} />
            <Route path={`${match.url}/opportunity-questions`} component={OpportunityQuestionsRoute} />
            <Route path={`${match.url}/probability`} component={ProbabilityRoute} />
            <Route path={`${match.url}/logout`} component={LogoutAll} />
            <Route path={`${match.url}/hrms-settings`} component={HRMSSettingsRoute} />
            <Route path={`${match.url}/rm-transfer`} component={RMTransferRoute} />
            <Route path ={`${match.url}/travel/calendar`}component ={TravelCalendarSettingRoutes}/>
        </Switch>
    )
}

export default PermissionRoute