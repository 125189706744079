import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  removeData,
  getData,
  KEY_TOAST_MESSAGE,
  setData,
} from "../../../../../utils/preferences";
import RequestHistory from "../../../../../component/widgets/request_history";
import {
  actionProposalPoolView,
  ACTION_PROPOSALPOOL_VIEW_SUCCESS,
} from "./action";
import {
  actionOpportunityPoolQuestion,
  ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS,
} from "./action";
import dateFormat from "dateformat";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ERROR, UNAUTHORIZED } from "../../../../../constants";
import { DataGrid } from "devextreme-react";
import { Column, Pager, Paging } from "devextreme-react/data-grid";
import moment from "moment";
import _ from "lodash";
import HTMLReactParser from "html-react-parser";
import { downloadFile, CalculateProposalMonth , linkify} from "../../../../../utils";
import DocumentIframe from "../../../../../component/popups/document_iframe";
import { FiTrash2 } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Controller } from "react-hook-form";

const ProposalSettingsView = (props) => {
  const [proposalPoolViewDetails, setProposalPoolViewDetails] = useState();
  const [showOpportunityPoolQuestions, setShowOpportunityPoolQuestions] =
    useState([]);
    const [tagUserList, setTagUserList] = useState([]);
  const [getProposalPlacement, setGetProposalPlacement] = useState([]);
  const [getBrokerNameList, setGetBrokerNameList] = useState([]);
  const [getInsurerNameList, setGetInsurerNameList] = useState([]);
  const [closureType, setClosureType] = useState(null);
  const [detailsData, setDetailsData] = useState(null);
  const [competitionIntermediaryType, setCompetitionIntermediaryType] =
    useState(null);
  const [quoteStatus, setQuoteStatus] = useState(null);
  const [proposalLostReason, setProposalLostReason] = useState(null);
  const [grossPremium, setGrossPremium] = useState(0);
  const [totalPremium, setTotalPremium] = useState(0);
  const [pincSharePremium, setPincSharePremium] = useState(0);
  const [showDocumentDetails, setShowDocumentDetails] = useState(false);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null);
  const [extension, setExtension] = useState(null);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [totalSharePercent, setTotalSharePercent] = useState();
  const [totalSharePremiumValue, setTotalSharePremiumValue] = useState();
  const [pincGrossPremiumAmount, setPincGrossPremiumAmount] = useState(null);
  const [totalGrossPremiumValue, setTotalGrossPremiumValue] = useState();

  const initialRender = () => {
    if (getData(KEY_TOAST_MESSAGE) != null) {
      toast.success(getData(KEY_TOAST_MESSAGE));
      removeData(KEY_TOAST_MESSAGE);
    }

    setDetailsData(JSON.parse(localStorage.getItem("detailsData")));
  };
  useEffect(initialRender, []);

  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    if (detailsData != null) {
      props.actionOpportunityPoolQuestion({ id: detailsData.id });
      props.actionProposalPoolView({
        policy_id: detailsData.id,
        opportunity_status: detailsData.opportunity_status,
        closure_type: detailsData.closure_type,
      });
    }
  }, [detailsData]);

  const columns = [
    { dataField: "insurer_name", caption: "Insurer Name", visible: true },
    { dataField: "branch_short_name", caption: "Branch Name", visible: true },
    { dataField: "status", caption: "Current Status", visible: true },
    { dataField: "quote_amount", caption: "Quote Amt (Net)", visible: true },
    {
      dataField: "quote_request_date",
      caption: "Quote Request Date",
      visible: true,
    },
    {
      dataField: "quote_received_date",
      caption: "Quote Receive Date",
      visible: true,
    },
    { dataField: "remark", caption: "Remarks", visible: true },
  ];

  const columnsInsurer = [
    // { dataField: "id", caption: "ID" },
    { dataField: "name", caption: "Insurer Name", visible: true },
  ];

  const columnsBroking = [
    // { dataField: "id", caption: "ID", visible: true },
    { dataField: "name", caption: "Broker Name", visible: true },
  ];

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
      arrColumns.push(
        <Column
          dataField={objColumn.dataField}
          caption={objColumn.caption}
        ></Column>
      );
    });
    return arrColumns;
  };

  const renderBrokerColumns = () => {
    var arrColumns = [];
    columnsBroking.forEach((objColumn, indexColumn) => {
      arrColumns.push(
        <Column
          dataField={objColumn.dataField}
          caption={objColumn.caption}
        ></Column>
      );
    });
    return arrColumns;
  };

  const renderInsurerColumns = () => {
    var arrColumns = [];
    columnsInsurer.forEach((objColumn, indexColumn) => {
      arrColumns.push(
        <Column
          dataField={objColumn.dataField}
          caption={objColumn.caption}
        ></Column>
      );
    });
    return arrColumns;
  };

  const renderPlacementRemark = (opportunity_remark) => {
    try {
      var formatText = opportunity_remark;
      if (
        proposalPoolViewDetails != null &&
        proposalPoolViewDetails.opportunity_remark != null
      ) {
        formatText = linkify(formatText);
      }
    } catch (e) {
      console.log("Format dashboad Text");
      console.log(e);
    }
    try {
      return HTMLReactParser(
        String(formatText)
          .substring(0, formatText.length)
          .trim()
          .replaceAll("\n", "<br>")
      );
    } catch (e) {
      return <>{formatText}</>;
    }
  };

  const onOpportunityPoolQuestionUpdate = () => {
    const { OpportunityPoolQuestion } = props;
    var OpportunityPoolQuestionResponse = Object.assign(
      {},
      OpportunityPoolQuestion
    );
    if (OpportunityPoolQuestionResponse.result === true) {
      setShowOpportunityPoolQuestions(OpportunityPoolQuestionResponse.response);
    } else {
      switch (OpportunityPoolQuestionResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onOpportunityPoolQuestionUpdate, [props.OpportunityPoolQuestion]);

  useEffect(() => {
    if (questionList != null && questionList.length > 0) {
      questionList.forEach((item, index) => {
        if (
          proposalPoolViewDetails?.closure?.premium_amount != null &&
          item?.share != null
        ) {
          const sharePremiumAmount =
            (Number(proposalPoolViewDetails?.closure?.premium_amount) *
              Number(item.share)) /
            100;
          if (item.share_premium_amount !== sharePremiumAmount.toFixed(2)) {
            const tempMobileArray = [...questionList];
            const objMobile = { ...tempMobileArray[index] };
            objMobile.share_premium_amount = sharePremiumAmount.toFixed(2);
            tempMobileArray[index] = objMobile;
            setQuestionList(tempMobileArray);
          }
        }
      });
      const totalShare = questionList.reduce((total, item) => {
        return total + (item.share ? Number(item.share) : 0);
      }, 0);
      setTotalSharePercent(totalShare);

      const SharePremiumAmount = questionList.reduce((total, item) => {
        return (
          total +
          (item.share_premium_amount ? Number(item.share_premium_amount) : 0)
        );
      }, 0);
      setTotalSharePremiumValue(SharePremiumAmount);

      const grossPremiumValue = questionList.reduce((total, item) => {
        return total + (item.gross_premium ? Number(item.gross_premium) : 0);
      }, 0);
      setTotalGrossPremiumValue(grossPremiumValue);
    } else {
      setTotalSharePercent(0);
      setTotalSharePremiumValue(0);
      setTotalGrossPremiumValue(0);
    }
  }, [questionList]);

  useEffect(() => {
    if (questionList != null && questionList.length > 0) {
      questionList.forEach((item, index) => {
        if (
          proposalPoolViewDetails?.closure?.premium_amount != null &&
          item?.share != null &&
          proposalPoolViewDetails?.closure?.cgst != null &&
          proposalPoolViewDetails?.closure?.cgst != "" &&
          proposalPoolViewDetails?.closure?.sgst != null &&
          proposalPoolViewDetails?.closure?.sgst != ""
        ) {
          const sharePremiumAmount =
            (Number(proposalPoolViewDetails?.closure?.premium_amount) *
              Number(item.share)) /
            100;
          const cgstAmount =
            (Number(sharePremiumAmount) *
              Number(proposalPoolViewDetails?.closure?.cgst)) /
            100;
          const sgstAmount =
            (Number(sharePremiumAmount) *
              Number(proposalPoolViewDetails?.closure?.sgst)) /
            100;
          const grossPremium = (
            Number(sharePremiumAmount) +
            cgstAmount +
            sgstAmount
          ).toFixed(2);

          // not NaN
          if (!isNaN(grossPremium) && item.gross_premium !== grossPremium) {
            const updatedQuestionList = [...questionList];
            const updatedItem = { ...updatedQuestionList[index] };
            updatedItem.gross_premium = grossPremium;
            updatedQuestionList[index] = updatedItem;
            setQuestionList(updatedQuestionList);
          }
        }
      });
    }
  }, [questionList]);

  const onProposalPoolViewUpdate = () => {
    const { ProposalPoolView } = props;
    var ProposalPoolViewResponse = Object.assign({}, ProposalPoolView);
    if (ProposalPoolViewResponse.result === true) {
      setGetProposalPlacement(
        ProposalPoolViewResponse?.response?.detail?.opportunity_insurers
      );
      setProposalPoolViewDetails(ProposalPoolViewResponse.response?.detail);
     
      setGetBrokerNameList(
        ProposalPoolViewResponse.response?.detail?.closure
          ?.opportunity_co_broker_success_detail_list
      );
      setGetInsurerNameList(
        ProposalPoolViewResponse.response?.detail?.closure
          ?.opportunity_co_insurer_success_detail_list
      );
      setQuoteStatus(
        ProposalPoolViewResponse.response?.detail?.opportunity_status
      );

      setTagUserList(ProposalPoolViewResponse?.response?.detail?.tag_users || "-");
      console.log("view response", ProposalPoolViewResponse)

      setClosureType(
        ProposalPoolViewResponse.response?.detail?.closure_type != null
          ? ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.closure_type
          : ""
      );
      if (
        ProposalPoolViewResponse.response?.detail?.opportunity_status ===
        "Proposal Success"
      ) {
        if (
          ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.closure_type ===
          "Co-broking"
        ) {
          setQuestionList(
            ProposalPoolViewResponse.response?.detail?.opportunity_success_details
              ?.opportunity_success_list
          );
        } else if (
          ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.closure_type ===
          "Co-insurer"
        ) {
          setQuestionList(
            ProposalPoolViewResponse.response?.detail?.opportunity_success_details
              ?.opportunity_success_list
          );
        } else if (
        ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.closure_type ===
        "Co-insurer & Co-broking"
      ) {
        setQuestionList(
          ProposalPoolViewResponse.response?.detail?.opportunity_success_details
            ?.opportunity_success_list
        );
      }}
      if (
        ProposalPoolViewResponse.response?.detail?.closure?.cgst != null &&
        ProposalPoolViewResponse.response?.detail?.closure?.sgst != null
      ) {
        var cgstAmount =
          (Number(
            ProposalPoolViewResponse.response?.detail?.closure?.premium_amount
          ) *
            Number(ProposalPoolViewResponse.response?.detail?.closure?.cgst)) /
          100;
        var sgstAmount =
          (Number(
            ProposalPoolViewResponse.response?.detail?.closure?.premium_amount
          ) *
            Number(ProposalPoolViewResponse.response?.detail?.closure?.sgst)) /
          100;

        var grossPremiumAmount =
          Number(
            ProposalPoolViewResponse.response?.detail?.closure?.premium_amount
          ) +
          cgstAmount +
          sgstAmount;
      }
      setGrossPremium(grossPremiumAmount);

      var totalPremium = 0;
      if (
        ProposalPoolViewResponse.response?.detail?.closure?.net_amount !=
          null &&
        ProposalPoolViewResponse.response?.detail?.closure?.gst_amount != null
      ) {
        totalPremium =
          Number(
            ProposalPoolViewResponse.response?.detail?.closure?.net_amount
          ) +
          Number(
            ProposalPoolViewResponse.response?.detail?.closure?.gst_amount
          );
      }
      setTotalPremium(totalPremium);

      var pincSharePremium = 0;
      if (
        ProposalPoolViewResponse.response?.detail?.closure?.premium_amount !=
          null &&
        ProposalPoolViewResponse.response?.detail?.closure?.pinc_share != null
      ) {
        pincSharePremium =
          (Number(
            ProposalPoolViewResponse.response?.detail?.closure?.premium_amount
          ) *
            Number(
              ProposalPoolViewResponse.response?.detail?.closure?.pinc_share
            )) /
          100;
      }
      setPincSharePremium(pincSharePremium);
    } else {
      switch (ProposalPoolViewResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProposalPoolViewResponse.message);
        default:
      }
    }
  };
  useEffect(onProposalPoolViewUpdate, [props.ProposalPoolView]);

  useEffect(() => {
    if (
      proposalPoolViewDetails != null &&
      proposalPoolViewDetails?.closure?.cgst != null &&
      proposalPoolViewDetails?.closure?.sgst != null
    ) {
      var cgstAmount =
        (Number(pincSharePremium) *
          Number(proposalPoolViewDetails?.closure?.cgst)) /
        100;
      var sgstAmount =
        (Number(pincSharePremium) *
          Number(proposalPoolViewDetails?.closure?.sgst)) /
        100;
      var pincGrossPremiumAmount =
        Number(pincSharePremium) + cgstAmount + sgstAmount;
      setPincGrossPremiumAmount(pincGrossPremiumAmount);
    }
  }, [pincSharePremium]);

  return (
    <section>
      <div>
        <div className="content-header row mb-1">
          <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
            <h5 className="content-header-title text-capitalize mb-0">
              Opportunity Pool View
            </h5>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
            <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Created Date
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.created_at != null
                    ? detailsData.created_at
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Opportunity ID
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {proposalPoolViewDetails != null &&
                  proposalPoolViewDetails?.id != null
                    ? proposalPoolViewDetails?.id
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Client Type
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {proposalPoolViewDetails != null &&
                  proposalPoolViewDetails?.client_type != null
                    ? _.capitalize(proposalPoolViewDetails?.client_type)
                    : "-"}
                </h4>
              </div>
              {detailsData?.oppurtunity_type === "Renewal" ? (
                <>
                  <div className="col-12 col-md-6 col-lg-6">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Control Number
                    </h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null && detailsData.control_no != null
                        ? detailsData.control_no
                        : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Policy Number
                    </h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null && detailsData?.policy_no != null
                        ? detailsData?.policy_no
                        : "-"}
                    </h4>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="col-12 col-md-6 col-lg-6">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Opportunity Type
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.oppurtunity_type != null
                    ? detailsData.oppurtunity_type
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Company Name
                </h6>
                <h4
                  className="font-medium-3 text-bold-700 text-justify text-primary cursor-pointer"
                  onClick={() => {
                    if (proposalPoolViewDetails.client_type === "lead") {
                      props.history.push({
                        pathname: "/leads/view",
                        state: {
                          client_id: proposalPoolViewDetails?.client_id,
                        },
                      });
                    } else if (
                      proposalPoolViewDetails.client_type === "client"
                    ) {
                      props.history.push({
                        pathname: "/client/view",
                        state: {
                          client_id: proposalPoolViewDetails?.client_id,
                        },
                      });
                    }
                  }}
                >
                  {detailsData != null && detailsData.customer_name != null
                    ? detailsData.customer_name
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Group Name
                </h6>
                <h4
                  className="font-medium-1 text-bold-700 text-justify text-primary cursor-pointer"
                  onClick={() => {
                    props.history.push({
                      pathname: "/client/group-level-view",
                      state: {
                        client_group_id:
                          proposalPoolViewDetails?.client_group_id,
                      },
                    });
                  }}
                >
                  {proposalPoolViewDetails != null &&
                  proposalPoolViewDetails.company_group_name != null
                    ? proposalPoolViewDetails.company_group_name
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Policy Type
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.policy_type != null
                    ? detailsData.policy_type
                    : "-"}
                </h4>
              </div>
              {detailsData != null &&
              detailsData.oppurtunity_type != null &&
              detailsData.oppurtunity_type != "Fresh" ? (
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Insurance Company Name
                  </h6>
                  <h4 className="font-medium-1 text-bold-700 text-justify">
                    {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails.insurer_short_name != null
                      ? proposalPoolViewDetails.insurer_short_name
                      : "-"}
                  </h4>
                </div>
              ) : (
                <></>
              )}
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Department
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.department_name != null
                    ? detailsData.department_name
                    : "-"}
                </h4>
              </div>
              {detailsData != null &&
              detailsData.oppurtunity_type != null &&
              detailsData.oppurtunity_type != "Fresh" ? (
                <>
                  <div className="col-12 col-md-6 col-lg-6">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Intermediary Type
                    </h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null &&
                      detailsData.intermediary_type != null
                        ? detailsData.intermediary_type
                        : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">{`${
                      props?.details?.intermediary_type != "Broker"
                        ? "Intermediary Details"
                        : "Broker Name"
                    }`}</h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null &&
                      detailsData.intermediary_value != null
                        ? detailsData.intermediary_value
                        : "-"}
                    </h4>
                  </div>
                </>
              ) : (
                <></>
              )}
              {props.proposalType === "assigned" ? (
                <div className="col-12 col-md-6 col-lg-6">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Assigned Specialist
                  </h6>
                  <h4 className="font-medium-1 text-bold-700 text-justify">
                    {detailsData != null &&
                    detailsData.product_specialist_name != null
                      ? detailsData.product_specialist_name
                      : "-"}
                  </h4>
                </div>
              ) : (
                <></>
              )}
              {detailsData != null &&
              detailsData.oppurtunity_type != null &&
              detailsData.oppurtunity_type != "Fresh" ? (
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Renewal Date
                  </h6>
                  <h4 className="font-medium-1 text-bold-700 text-justify">
                    {detailsData != null && detailsData.expiring_date != null
                      ? detailsData.expiring_date
                      : "-"}
                  </h4>
                </div>
              ) : (
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Expected Closure Date
                  </h6>
                  <h4 className="font-medium-1 text-bold-700 text-justify">
                    {detailsData != null && detailsData.expiring_date != null
                      ? detailsData.expiring_date
                      : "-"}
                  </h4>
                </div>
              )}
              {/* {detailsData?.expiring_date && (
                <div className="col-12 col-md-6 mb-1">
                  <div className="form-group">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Proposal Month
                    </h6>
                    {detailsData?.expiring_date ? CalculateProposalMonth(moment(detailsData?.expiring_date, 'DD/MM/YYYY')):'-'}
                  </div>
                </div>
              )} */}
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Expected Premium
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {proposalPoolViewDetails != null &&
                  proposalPoolViewDetails.expected_premium != null ? (
                    <div>
                      <span>&#8377;</span>{" "}
                      {proposalPoolViewDetails.expected_premium}{" "}
                    </div>
                  ) : (
                    "-"
                  )}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Probability
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.probability != null
                    ? detailsData.probability + "%"
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
    Tag Team Members
  </h6>
  <div className="font-medium-1 text-bold-700 text-justify">
    {tagUserList.length > 0 ? (
      tagUserList.map((user, index) => (
        <div key={index}>
          {user.tag_user_profile?.full_name
            ? _.capitalize(user.tag_user_profile.full_name)
            : "-"}
        </div>
      ))
    ) : (
      "-"
    )}
  </div>
</div>




              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Created By
                </h6>
                <h4 className="font-medium-3 text-bold-700 text-justify">
                  {detailsData != null && detailsData.created_by != null
                    ? detailsData.created_by
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Create Date
                </h6>
                <h4 className="font-medium-3 text-bold-700 text-justify">
                  {proposalPoolViewDetails != null &&
                  proposalPoolViewDetails?.created_at != null
                    ? proposalPoolViewDetails?.created_at
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Assigned RM
                </h6>
                <h4
                  className="font-medium-1 text-bold-700 text-justify text-primary cursor-pointer"
                  onClick={() => {
                    props.history.push({
                      pathname: "/profile",
                      state: {
                        user_id: proposalPoolViewDetails?.assigned_rm_id,
                      },
                    });
                  }}
                >
                  {proposalPoolViewDetails != null &&
                  proposalPoolViewDetails.assigned_rm_name != null
                    ? proposalPoolViewDetails.assigned_rm_name
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Product RM
                </h6>
                <h4
                  className="font-medium-1 text-bold-700 text-justify text-primary cursor-pointer"
                  onClick={() => {
                    props.history.push({
                      pathname: "/profile",
                      state: {
                        user_id: proposalPoolViewDetails?.product_specialist_id,
                      },
                    });
                  }}
                >
                  {proposalPoolViewDetails != null &&
                  proposalPoolViewDetails.ps_name != null
                    ? proposalPoolViewDetails.ps_name
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Retention RM
                </h6>
                <h4
                  className="font-medium-1 text-bold-700 text-justify text-primary cursor-pointer"
                  onClick={() => {
                    props.history.push({
                      pathname: "/profile",
                      state: {
                        user_id: proposalPoolViewDetails?.retention_user_id,
                      },
                    });
                  }}
                >
                  {proposalPoolViewDetails != null &&
                  proposalPoolViewDetails.retention_rm_name != null
                    ? proposalPoolViewDetails.retention_rm_name
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  RM Remark
                </h6>
                <h4 className="font-medium-3 text-bold-700 text-justify" style={{ whiteSpace: 'pre-wrap' }}>
                  {proposalPoolViewDetails != null &&
                  proposalPoolViewDetails?.client_remark != null &&
                  proposalPoolViewDetails?.client_remark != "null" &&
                  proposalPoolViewDetails?.client_remark != "undefined"
                    ? proposalPoolViewDetails?.client_remark
                    : "-"}
                </h4>
              </div>
            </div>
            {showOpportunityPoolQuestions.opportunity_questions != null &&
            showOpportunityPoolQuestions.opportunity_questions.length > 0 ? (
              <>
                <div className="col-12 col-md-12 col-lg-12 pl-0">
                  <h4 className="modal-title" id="myModalLabel1">
                    More Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1 mt-1 d-flex">
                      System Probability
                    </h6>
                    <h4 className="font-small-2 text-bold-700 text-justify d-flex">
                      {showOpportunityPoolQuestions.total_probability != null
                        ? showOpportunityPoolQuestions.total_probability
                        : "-"}
                    </h4>
                  </div>
                  {showOpportunityPoolQuestions.opportunity_questions != null &&
                  showOpportunityPoolQuestions.opportunity_questions.length >
                    0 ? (
                    showOpportunityPoolQuestions.opportunity_questions.map(
                      (item, index) => (
                        <div className="col-12 col-md-6 col-lg-6">
                          <h6 className="font-small-2 text-uppercase blue-grey darken-1 mt-1 d-flex">
                            {item.question}
                          </h6>
                          <h4 className="font-small-2 text-bold-700 text-justify mt-1 d-flex">
                            {item.question_value}
                          </h4>
                        </div>
                      )
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {getProposalPlacement != null && getProposalPlacement.length > 0 ? (
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12">
                  <DataGrid
                    dataSource={getProposalPlacement}
                    showBorders={true}
                    // sorting={false}
                    columnAutoWidth={true}
                  >
                    {renderColumns()}
                    <Pager
                      visible={true}
                      showPageSizeSelector={false}
                      showNavigationButtons={true}
                      allowedPageSizes={[4, 6, 10]}
                    />
                    <Paging
                      defaultPageIndex={0}
                      defaultPageSize={10}
                      enabled={true}
                    />
                  </DataGrid>
                </div>
              </div>
            ) : (
              <></>
            )}

                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                <h6 className="font-small-3 text-uppercase blue-grey darken-1">Quote Status</h6>
                                <h4 className= {`font-small-3 text-bold-700 text-justify ${proposalPoolViewDetails?.opportunity_status == "Proposal Success"? 'text-success' :'' }`}>

                                    {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_status != null ? proposalPoolViewDetails?.opportunity_status : '-'}
                                </h4>
                            </div>
                            {/* Proposal Rejected */}
                            {
                                quoteStatus === 'Quote Rejected' ?
                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Rejection Reason</h6>
                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                            {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_sub_status != null ? proposalPoolViewDetails?.opportunity_sub_status : '-'}
                                        </h4>
                                    </div>
                                    :
                                    <></>
                            }

                            {/* Proposal Lost */}
                            {
                                quoteStatus === 'Proposal Lost' ?
                                    <>
                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Reason</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_sub_status != null ? proposalPoolViewDetails?.opportunity_sub_status : '-'}
                                            </h4>
                                        </div>
                                        {
                                            proposalLostReason === "Others" ?

                                                <div className="col-12 col-md-6 col-lg-4 mb-1">
                                                    <h6 className="font-small-3 text-uppercase blue-grey darken-1">Other Reason</h6>
                                                    <h4 className="font-small-3 text-bold-700 text-justify">
                                                        {proposalPoolViewDetails != null && proposalPoolViewDetails?.created_at != null ? proposalPoolViewDetails?.created_at : '-'}
                                                    </h4>
                                                </div>

                                                :
                                                <></>
                                        }
                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Competition Intermediary Type</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.closure?.intermediary_type != null ? proposalPoolViewDetails?.closure?.intermediary_type : '-'}
                                            </h4>
                                        </div>

                                        {
                                            competitionIntermediaryType === "Broker" ?
                                                <>
                                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Broker's name</h6>
                                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                                            {proposalPoolViewDetails != null && proposalPoolViewDetails?.closure?.intermediary_name != null ? proposalPoolViewDetails?.closure?.intermediary_name : '-'}
                                                        </h4>
                                                    </div>
                                                </>
                                                :

                                                <div className="col-12 col-md-6 col-lg-4 mb-1">
                                                    <h6 className="font-small-3 text-uppercase blue-grey darken-1">Intermediary Name</h6>
                                                    <h4 className="font-small-3 text-bold-700 text-justify">
                                                        {proposalPoolViewDetails != null && proposalPoolViewDetails?.closure?.intermediary_name != null ? proposalPoolViewDetails?.closure?.intermediary_name : '-'}
                                                    </h4>
                                                </div>

                                        }

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Insurer Name</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.closure?.name != null ? proposalPoolViewDetails?.closure?.name : '-'}
                                            </h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Net Amount</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.closure?.net_amount != null ? proposalPoolViewDetails?.closure?.net_amount : '-'}
                                            </h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">GST Amount</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.closure?.gst_amount != null ? proposalPoolViewDetails?.closure?.gst_amount : '-'}
                                            </h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Total Premium</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {totalPremium != null ? totalPremium.toFixed(2) : '-'}
                                            </h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Payment Sent to Insurer</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.closure?.payment_sent_to_insurer != null ? proposalPoolViewDetails?.closure?.payment_sent_to_insurer : '-'}
                                            </h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Client Responsive</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.closure?.client_response != null ? proposalPoolViewDetails?.closure?.client_response : '-'}
                                            </h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Sales Remarks</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.closure?.sales_remark != null && proposalPoolViewDetails?.closure?.sales_remark != 'null' ? proposalPoolViewDetails?.closure?.sales_remark : '-'}
                                            </h4>
                                        </div>


                                    </>
                                    :
                                    <></>
                            }

                            {/* Proposal Success */}
                            {
                                quoteStatus === 'Proposal Success' ?
                                <>
                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Closure Type</h6>
                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                            {closureType || "-"}
                                        </h4>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Sum Insured</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.sum_insured != null ? parseFloat(proposalPoolViewDetails?.sum_insured) : '-'}
                                            </h4>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">OD Premium (Quote Amt (Net)</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.premium_amount != null ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.premium_amount) : '-'}
                                            </h4>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">TP Premium</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.tp_net_premium != null ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.tp_net_premium) : '-' }
                                            </h4>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Net Premium</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.total_net_premium != null ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.total_net_premium) : '-'}
                                            </h4>
                                        </div>
                                      <div className="col-12 col-md-6 col-lg-4 mb-1">
                                          <h6 className="font-small-3 text-uppercase blue-grey darken-1">Gross Premium</h6>
                                          <h4 className="font-small-3 text-bold-700 text-justify">
                                              {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list != null ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.gross_premium) : '-' }
                                          </h4>
                                    </div>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                quoteStatus === 'Proposal Success' && closureType === 'Standard' ?
                                <>
                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Insurer Business booked with</h6>
                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                            {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list != null ? proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.entity_name : '-'}
                                        </h4>
                                    </div>
                                </>
                                    :
                                    <></>
                            }

                            {
                                quoteStatus === 'Proposal Success' && closureType != null ?
                                    <>
                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">CGST %</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list?.length > 0 && proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0].cgst != null ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0].cgst) : '-'}
                                            </h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">SGST %</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                            {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list?.length > 0 && proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0].sgst != null ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0].sgst) : '-'}
                                            </h4>
                                        </div>
                                        {/* <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Gross Premium</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {grossPremium != null ? grossPremium.toFixed(2) : '-'}
                                            </h4>
                                        </div> */}
                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Installment</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.instalment != null ? proposalPoolViewDetails?.opportunity_success_details?.instalment == 0 ? "No installment" : parseFloat(proposalPoolViewDetails?.opportunity_success_details?.instalment) : '-'}
                                            </h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Business Closure Date</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.business_closure_date != null ? proposalPoolViewDetails?.opportunity_success_details?.business_closure_date : '-'}
                                            </h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Policy Start Date</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.policy_start_date != null ? proposalPoolViewDetails?.opportunity_success_details?.policy_start_date : '-'}
                                            </h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Policy End Date</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {proposalPoolViewDetails != null && proposalPoolViewDetails?.end_date != null && proposalPoolViewDetails?.end_date != '0000-00-00 00:00:00' ? moment(proposalPoolViewDetails?.end_date).format('DD/MM/yyyy') : '-'}
                                            </h4>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                        <div className="row">
                            {/* {quoteStatus === 'Proposal Success' && closureType != null && closureType === "Co-insurer & Co-broking" ?
                                <>
                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Insurer Company</h6>
                                        {
                                            getInsurerNameList != null && getInsurerNameList.length > 0 ?
                                                <div className="row">
                                                    <div className="col-12 col-lg-12 col-md-12">
                                                        <DataGrid
                                                            dataSource={getInsurerNameList}
                                                            sorting={false}
                                                            showBorders={true}
                                                            columnAutoWidth={true}
                                                        >
                                                            {renderInsurerColumns()}
                                                            <Pager
                                                                visible={true}
                                                                showPageSizeSelector={false}
                                                                showNavigationButtons={true}
                                                                allowedPageSizes={[4, 6, 10]}
                                                            />
                                                            <Paging
                                                                defaultPageIndex={0}
                                                                defaultPageSize={10}
                                                                enabled={true}
                                                            />
                                                        </DataGrid>
                                                    </div>
                                                </div>
                                                :
                                                <h4 className="font-small-3 text-bold-700 text-justify">
                                                    -
                                                </h4>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Broker Name</h6>
                                        {
                                            getBrokerNameList != null && getBrokerNameList.length > 0 ?
                                                <div className="row">
                                                    <div className="col-12 col-lg-12 col-md-12">
                                                        <DataGrid
                                                            dataSource={getBrokerNameList}
                                                            showBorders={true}
                                                            sorting={false}
                                                            columnAutoWidth={true}
                                                        >
                                                            {renderBrokerColumns()}
                                                            <Pager
                                                                visible={true}
                                                                showPageSizeSelector={false}
                                                                showNavigationButtons={true}
                                                                allowedPageSizes={[4, 6, 10]}
                                                            />
                                                            <Paging
                                                                defaultPageIndex={0}
                                                                defaultPageSize={10}
                                                                enabled={true}
                                                            />
                                                        </DataGrid>
                                                    </div>
                                                </div>
                                                :
                                                <h4 className="font-small-3 text-bold-700 text-justify">
                                                    -
                                                </h4>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Remarks</h6>
                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                            {proposalPoolViewDetails != null && proposalPoolViewDetails?.remarks != null ? proposalPoolViewDetails?.remarks : '-'}
                                        </h4>
                                    </div>
                                </>
                                :
                                <></>
                            } */}
                            {/* END COMMENTED FOR CO BROKING / CO INSURER */}
                            {
                                proposalPoolViewDetails?.product_specialist_id != 0 && proposalPoolViewDetails?.product_specialist_id != '' && proposalPoolViewDetails?.product_specialist_id != null ?
                                    <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Placement Remark</h6>
                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                            {
                                                proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_remark != null && proposalPoolViewDetails?.opportunity_remark != 'null' && proposalPoolViewDetails?.opportunity_remark != 'undefined' ?
                                                    renderPlacementRemark(proposalPoolViewDetails?.opportunity_remark) : '-'
                                            }
                                            {/* {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_remark != null ? proposalPoolViewDetails?.opportunity_remark : '-'} */}
                                        </h4>
                                    </div> :
                                    <></>
                            }
                            {
                                quoteStatus === 'Proposal Lost' || quoteStatus === 'Proposal Success' ?
                                    <>
                                        <div className="col-12 col-md-12 col-lg-12 mb-1">
                                            <h4 className="text-uppercase font-small-3 mb-0">Document</h4>
                                            <div className="mt-1 row">
                                                {
                                                    (proposalPoolViewDetails != null && proposalPoolViewDetails?.documents != null && proposalPoolViewDetails?.documents.length > 0) ?
                                                        proposalPoolViewDetails.documents.map((objLink, index) => (
                                                            <>
                                                                <div className='col-12 col-md-4 col-xl-3'>
                                                                    <div className='card shadow bg-light rounded mb-1 '>
                                                                        <div className='card-body d-flex  break-word word-break justify-content-between py-1'>
                                                                            {/* <a target="_blank" href={objLink.document} download="Meeting Document">Document {index + 1}</a> */}
                                                                            <a className="primary" 
                                                                                style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                                                                                onClick={() => {
                                                                                    if (objLink.full_url?.split('.').pop() == 'pdf' ||
                                                                                        objLink.full_url?.split('.').pop() == 'PDF' ||
                                                                                        objLink.full_url?.split('.').pop() == 'png' ||
                                                                                        objLink.full_url?.split('.').pop() == 'jpg' ||
                                                                                        objLink.full_url?.split('.').pop() == 'jpeg' ||
                                                                                        objLink.full_url?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(objLink.full_url)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(objLink.full_url?.split('.').pop())
                                                                                        setDocumentDetails(objLink)
                                                                                    } else {
                                                                                        downloadFile(objLink.full_url);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {/* Document {index + 1} */}
                                                                                {objLink.actual_uploaded_file_name}
                                                                            </a>
                                                                            {/* <span className='cursor-pointer'>  <FiTrash2 size={16} className="primary" /></span> */}
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                        )) : <h4 className="font-small-3 text-bold-700 text-justify pl-1">-</h4>
                                                }
                                            </div>
                                        </div>


                                    </>
                                    :
                                    <></>
                            }

              {closureType === "Standard" && proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list?.length > 0 ? (
                <>
                  <div className="col-12 col-md-12 col-lg-12">
                    <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                      <span>
                        {"Brokerage Details"}
                      </span>
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-1">
                    <h6 className="font-small-3 text-uppercase blue-grey darken-1">OD Brokerage % </h6>
                    <h4 className="font-small-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.od_brokerage_perc ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.od_brokerage_perc) : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mb-1">
                    <h6 className="font-small-3 text-uppercase blue-grey darken-1">OD Brokerage Amount</h6>
                    <h4 className="font-small-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.od_brokerage_amount ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.od_brokerage_amount) : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-1">
                    <h6 className="font-small-3 text-uppercase blue-grey darken-1">TP Brokerage % </h6>
                    <h4 className="font-small-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.tp_brokerage_perc ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.tp_brokerage_perc) : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-1">
                    <h6 className="font-small-3 text-uppercase blue-grey darken-1"> TP Brokerage Amount</h6>
                    <h4 className="font-small-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.tp_brokerage_amount ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.tp_brokerage_amount) : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-1">
                    <h6 className="font-small-3 text-uppercase blue-grey darken-1"> Reward On</h6>
                    <h4 className="font-small-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.reward_on}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-1">
                    <h6 className="font-small-3 text-uppercase blue-grey darken-1">Reward % </h6>
                    <h4 className="font-small-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.reward_perc ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.reward_perc) : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-1">
                    <h6 className="font-small-3 text-uppercase blue-grey darken-1"> Reward Amount </h6>
                    <h4 className="font-small-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.reward_amount ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.reward_amount) : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-1">
                    <h6 className="font-small-3 text-uppercase blue-grey darken-1">  Bonus %</h6>
                    <h4 className="font-small-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.bonus_reward_perc ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.bonus_reward_perc) : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-1">
                    <h6 className="font-small-3 text-uppercase blue-grey darken-1">Bonus Amount </h6>
                    <h4 className="font-small-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.bonus_reward_amount ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.bonus_reward_amount) : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-1">
                    <h6 className="font-small-3 text-uppercase blue-grey darken-1"> Total Brokerage Amount</h6>
                    <h4 className="font-small-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.total_brokerage_amount ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.total_brokerage_amount) : "-"}
                    </h4>
                  </div>
                </>
              ) : (
                <></>
              )}
              
              <div className="col-12 col-md-12 col-lg-12 mb-1">
                                            <h4 className="text-uppercase font-small-3 mb-0">Policy Document</h4>
                                            <div className="mt-1 row">
                                                {
                                                    (proposalPoolViewDetails != null && proposalPoolViewDetails?.policy_documents != null && proposalPoolViewDetails?.policy_documents.length > 0) ?
                                                        proposalPoolViewDetails.policy_documents.map((objLink, index) => (
                                                            <>
                                                                <div className='col-12 col-md-4 col-xl-3'>
                                                                    <div className='card shadow bg-light rounded mb-1 '>
                                                                        <div className='card-body d-flex  break-word word-break justify-content-between py-1'>
                                                                            {/* <a target="_blank" href={objLink.document} download="Meeting Document">Document {index + 1}</a> */}
                                                                            <a className="primary" 
                                                                                style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                                                                                onClick={() => {
                                                                                    if (objLink.full_url?.split('.').pop() == 'pdf' ||
                                                                                        objLink.full_url?.split('.').pop() == 'PDF' ||
                                                                                        objLink.full_url?.split('.').pop() == 'png' ||
                                                                                        objLink.full_url?.split('.').pop() == 'jpg' ||
                                                                                        objLink.full_url?.split('.').pop() == 'jpeg' ||
                                                                                        objLink.full_url?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(objLink.full_url)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(objLink.full_url?.split('.').pop())
                                                                                        setDocumentDetails(objLink)
                                                                                    } else {
                                                                                        downloadFile(objLink.full_url);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {/* Document {index + 1} */}
                                                                                {objLink.actual_uploaded_file_name}
                                                                            </a>
                                                                            {/* <span className='cursor-pointer'>  <FiTrash2 size={16} className="primary" /></span> */}
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                        )) : <h4 className="font-small-3 text-bold-700 text-justify pl-1">-</h4>
                                                }
                                            </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 mb-1">
                                            <h4 className="text-uppercase font-small-3 mb-0">Claim Document</h4>
                                            <div className="mt-1 row">
                                                {
                                                    (proposalPoolViewDetails != null && proposalPoolViewDetails?.claim_documents != null && proposalPoolViewDetails?.claim_documents.length > 0) ?
                                                        proposalPoolViewDetails.claim_documents.map((objLink, index) => (
                                                            <>
                                                                <div className='col-12 col-md-4 col-xl-3'>
                                                                    <div className='card shadow bg-light rounded mb-1 '>
                                                                        <div className='card-body d-flex  break-word word-break justify-content-between py-1'>
                                                                            {/* <a target="_blank" href={objLink.document} download="Meeting Document">Document {index + 1}</a> */}
                                                                            <a className="primary" 
                                                                                style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                                                                                onClick={() => {
                                                                                    if (objLink.full_url?.split('.').pop() == 'pdf' ||
                                                                                        objLink.full_url?.split('.').pop() == 'PDF' ||
                                                                                        objLink.full_url?.split('.').pop() == 'png' ||
                                                                                        objLink.full_url?.split('.').pop() == 'jpg' ||
                                                                                        objLink.full_url?.split('.').pop() == 'jpeg' ||
                                                                                        objLink.full_url?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(objLink.full_url)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(objLink.full_url?.split('.').pop())
                                                                                        setDocumentDetails(objLink)
                                                                                    } else {
                                                                                        downloadFile(objLink.full_url);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {/* Document {index + 1} */}
                                                                                {objLink.actual_uploaded_file_name}
                                                                            </a>
                                                                            {/* <span className='cursor-pointer'>  <FiTrash2 size={16} className="primary" /></span> */}
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                        )) : <h4 className="font-small-3 text-bold-700 text-justify pl-1">-</h4>
                                                }
                                            </div>
                </div>

                <div className="col-12 col-md-12 col-lg-12 mb-1">
                                            <h4 className="text-uppercase font-small-3 mb-0">Other Document</h4>
                                            <div className="mt-1 row">
                                                {
                                                    (proposalPoolViewDetails != null && proposalPoolViewDetails?.other_documents != null && proposalPoolViewDetails?.other_documents.length > 0) ?
                                                        proposalPoolViewDetails.other_documents.map((objLink, index) => (
                                                            <>
                                                                <div className='col-12 col-md-4 col-xl-3'>
                                                                    <div className='card shadow bg-light rounded mb-1 '>
                                                                        <div className='card-body d-flex  break-word word-break justify-content-between py-1'>
                                                                            {/* <a target="_blank" href={objLink.document} download="Meeting Document">Document {index + 1}</a> */}
                                                                            <a className="primary" 
                                                                                style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                                                                                onClick={() => {
                                                                                    if (objLink.full_url?.split('.').pop() == 'pdf' ||
                                                                                        objLink.full_url?.split('.').pop() == 'PDF' ||
                                                                                        objLink.full_url?.split('.').pop() == 'png' ||
                                                                                        objLink.full_url?.split('.').pop() == 'jpg' ||
                                                                                        objLink.full_url?.split('.').pop() == 'jpeg' ||
                                                                                        objLink.full_url?.split('.').pop() == 'gif'
                                                                                    ) {
                                                                                        setSelectedDocumentIndex(objLink.full_url)
                                                                                        setShowDocumentDetails(true);
                                                                                        setExtension(objLink.full_url?.split('.').pop())
                                                                                        setDocumentDetails(objLink)
                                                                                    } else {
                                                                                        downloadFile(objLink.full_url);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {/* Document {index + 1} */}
                                                                                {objLink.actual_uploaded_file_name}
                                                                            </a>
                                                                            {/* <span className='cursor-pointer'>  <FiTrash2 size={16} className="primary" /></span> */}
                                                                            <a id="downloadLink" className="d-none"></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                        )) : <h4 className="font-small-3 text-bold-700 text-justify pl-1">-</h4>
                                                }
                                            </div>
                </div>

              {quoteStatus != null &&
                quoteStatus === "Proposal Success" &&
                closureType != null &&
                (closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking" || closureType === "Co-broking") ? (
                <>
                <div className="col-12 col-md-12 col-lg-12" onClick={() => console.log(proposalPoolViewDetails)}>
                    <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                      <span>
                        {"Brokerage Details"}
                      </span>
                    </h4>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 ">
                      <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="form-body">
                            {questionList != null &&
                              questionList.length > 0 ? (
                              questionList.map((item, index) => (
                                <>
                                  <div className="border-bottom-primary px-0 py-2">
                                    <div className="row">
                                      {/* {closureType !== null && closureType === "Co-insurer & Co-broking" ? <>
                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                          <h6 className="font-small-3 text-uppercase blue-grey darken-1"> Insurer/Broker </h6>
                                          <h4 className="font-small-3 text-bold-700 text-justify">
                                          {item.entity_name}
                                          </h4>
                                        </div>
                                      </> : <></>} */}
                                      {closureType != null &&
                                        (closureType ===
                                          "Co-insurer" || (closureType === "Co-insurer & Co-broking" && item.entity_type === "INSURER")) ? (
                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                          <h6 className="font-small-3 text-uppercase blue-grey darken-1">Insurance Company </h6>
                                          <h4 className="font-small-3 text-bold-700 text-justify">
                                          {item.entity_name}
                                          </h4>
                                        </div>
                                      ) : (
                                        (closureType === "Co-broking" || (closureType === "Co-insurer & Co-broking" && item.entity_type === "BROKER")) ?
                                          <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">Broker Name </h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                            {item.entity_name}
                                            </h4>
                                          </div> : <></>
                                      )}
                                      
                                      <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Type</h6>
                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                        {item.type}
                                        </h4>
                                      </div>
                                      <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1"> Share %</h6>
                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                        {parseFloat(item.share)}
                                        </h4>
                                      </div>
                                      {
                                        quoteStatus === 'Proposal Success' && closureType != null && closureType === "Co-insurer & Co-broking" ?
                                          <>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">PINC Share %</h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {item.pinc_share ? parseFloat(item.pinc_share) : "-"}
                                              </h4>
                                            </div>
                                            {/* <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">PINC Share Premium</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {pincSharePremium != null ? pincSharePremium.toFixed(2) : '-'}
                                            </h4>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 mb-1">
                                            <h6 className="font-small-3 text-uppercase blue-grey darken-1">PINC Gross Premium</h6>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                                {pincGrossPremiumAmount != null ? pincGrossPremiumAmount.toFixed(2) : '-'}
                                            </h4>
                                        </div> */}

                                          </>
                                          :
                                          <></>
                                      }
                                      {
                                        (closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking" || (closureType === "Co-broking" && item?.entity_id == 620)) && (
                                          <>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1"> Share OD Premium</h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {parseFloat(item.od_premium)}
                                              </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1"> Share TP Premium</h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {parseFloat(item.tp_premium)}
                                              </h4>
                                            </div>
                                          </>
                                        )
                                      }

                                      <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1"> Share Net Premium</h6>
                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                        {parseFloat(item.net_premium)}
                                        </h4>
                                      </div>
                                      <div className="col-12 col-md-6 col-lg-4 mb-1">
                                        <h6 className="font-small-3 text-uppercase blue-grey darken-1">Gross Premium </h6>
                                        <h4 className="font-small-3 text-bold-700 text-justify">
                                        {parseFloat(item.gross_premium)}
                                        </h4>
                                      </div>
                                      {
                                        (((closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking") 
                                        && (proposalPoolViewDetails?.opportunity_success_details?.brokerage_paid_by === "Follow" || (proposalPoolViewDetails?.opportunity_success_details?.brokerage_paid_by === "Lead" && item.type === "Lead")))|| item?.entity_id == 620) && (
                                          <>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">OD Brokerage % </h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {parseFloat(item.od_brokerage_perc)}
                                              </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">OD Brokerage Amount </h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {parseFloat(item.od_brokerage_amount)}
                                              </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">TP Brokerage % </h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {parseFloat(item.tp_brokerage_perc)}
                                              </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">TP Brokerage Amount </h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {parseFloat(item.tp_brokerage_amount)}
                                              </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">Reward On </h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {item.reward_on}
                                              </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">Reward % </h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {item.reward_perc ? parseFloat(item.reward_perc) : "-"}
                                              </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">Reward Amount </h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {parseFloat(item.reward_amount)}
                                              </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">Bonus % </h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {parseFloat(item.bonus_reward_perc)}
                                              </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">Bonus Amount </h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {parseFloat(item.bonus_reward_amount)}
                                              </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">Total Brokerage Amount </h6>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                              {parseFloat(item.total_brokerage_amount)}
                                              </h4>
                                            </div>
                                          </>
                                        )
                                      }
                                    </div>
                                  </div>
                                </>
                              ))
                            ) : (
                              <></>
                            )}

                            {/* <div className="col-12 col-md-6 col-lg-4 mb-1">
                              <h6 className="font-small-3 text-uppercase blue-grey darken-1"> Total Share % </h6>
                              <h4 className="font-small-3 text-bold-700 text-justify">
                              </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                              <h6 className="font-small-3 text-uppercase blue-grey darken-1">  Total Share Premium Amount </h6>
                              <h4 className="font-small-3 text-bold-700 text-justify">
                              </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 mb-1">
                              <h6 className="font-small-3 text-uppercase blue-grey darken-1"> Total Gross Premium </h6>
                              <h4 className="font-small-3 text-bold-700 text-justify">
                              </h4>
                            </div>*/}
                          </div> 

                        </div>
                      </div>
                    </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="form-actions border-0 mt-0 pt-0 float-none">
              <button
                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                onClick={(event) => {
                  event.preventDefault();
                  // props.history.push({
                  //     pathname: '/proposal_pool',
                  //     state: {
                  //         proposalType: props.location.state.type,
                  //     }
                  // });
                  window.close();
                  // props.history.goBack();
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      <DocumentIframe
        {...props}
        show={showDocumentDetails}
        documentDetails={selectedDocumentIndex}
        detailsOfDocument={documentDetails}
        extension={extension}
        handleClose={() => {
          setShowDocumentDetails(false);
        }}
      />
    </section>
  );
};

const mapStateToProps = (state) => {
  var ProposalPoolView = null;
  var OpportunityPoolQuestion = null;
  if (
    state.ProposalPoolViewReducer != null &&
    Object.keys(state.ProposalPoolViewReducer).length > 0 &&
    state.ProposalPoolViewReducer.constructor === Object
  ) {
    switch (state.ProposalPoolViewReducer.tag) {
      case ACTION_PROPOSALPOOL_VIEW_SUCCESS:
        ProposalPoolView = Object.assign(
          {},
          state.ProposalPoolViewReducer.data
        );
        delete state.ProposalPoolViewReducer;
        return { ProposalPoolView };
      case ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS:
        OpportunityPoolQuestion = Object.assign(
          {},
          state.ProposalPoolViewReducer.data
        );
        delete state.ProposalPoolViewReducer;
        return { OpportunityPoolQuestion };
      default:
    }
  }
  return {
    ProposalPoolView,
    OpportunityPoolQuestion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { actionProposalPoolView, actionOpportunityPoolQuestion },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(ProposalSettingsView)
);
