import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_EMPLOYEE_GET_REPORTING, API_EMPLOYEE_CHANGE_USER_STATUS, API_EMPLOYEE_LIST, API_EMPLOYEE_STATUS, API_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER,API_CHECK_EMPLOYEE_DEPENDENCY } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

//hrms employee

export const ACTION_EMPLOYEE_GET_REPORTING_LOADING = 'API_EMPLOYEE_GET_REPORTING_LOADING';
export const ACTION_EMPLOYEE_GET_REPORTING_SUCCESS = 'API_EMPLOYEE_GET_REPORTING_SUCCESS';

export const ACTION_EMPLOYEE_STATUS_LOADING = 'ACTION_EMPLOYEE_STATUS_LOADING';
export const ACTION_EMPLOYEE_STATUS_SUCCESS = 'ACTION_EMPLOYEE_STATUS_SUCCESS';

export const ACTION_EMPLOYEE_LIST_LOADING = 'ACTION_EMPLOYEE_LIST_LOADING';
export const ACTION_EMPLOYEE_LIST_SUCCESS = 'ACTION_EMPLOYEE_LIST_SUCCESS';

export const ACTION_EMPLOYEE_ACTIVE_LIST_LOADING = 'ACTION_EMPLOYEE_ACTIVE_LIST_LOADING';
export const ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS = 'ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS';

export const ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_LOADING = 'ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_LOADING';
export const ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS = 'ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS';

export function actionEmployeeGetReporting(params) {
    return (dispatch, getState) =>
        Api.post(API_EMPLOYEE_GET_REPORTING,params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EMPLOYEE_GET_REPORTING_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_GET_REPORTING_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_EMPLOYEE_STATUS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_STATUS_LOADING, { loading: false, data }));
        });
}



export function actionEmployeeList(params) {
    return (dispatch, getState) =>
        Api.get(API_EMPLOYEE_LIST + '/all').then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_LIST_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeListActive(params) {
    return (dispatch, getState) =>
        Api.get(API_EMPLOYEE_LIST + '/active').then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_ACTIVE_LIST_LOADING, { loading: false, data }));
        });
}

export function actionReimbursementFinanceApprovalManager() {
    return (dispatch, getState) => 
        Api.get(API_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER).then((res) => {
        const data = res;
        PRINT('ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_LOADING, { loading: false, data }));
    });
}