import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_CHECK_EMPLOYEE_DEPENDENCY, API_EMPLOYEE_LIST, API_EMPLOYEE_STATUS } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const ACTION_EMPLOYEE_LIST_LOADING = 'ACTION_EMPLOYEE_LIST_LOADING';
export const ACTION_EMPLOYEE_LIST_SUCCESS = 'ACTION_EMPLOYEE_LIST_SUCCESS';

export const ACTION_EMPLOYEE_ACTIVE_LIST_LOADING = 'ACTION_EMPLOYEE_ACTIVE_LIST_LOADING';
export const ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS = 'ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS';

export const ACTION_EMPLOYEE_INACTIVE_LIST_LOADING = 'ACTION_EMPLOYEE_INACTIVE_LIST_LOADING';
export const ACTION_EMPLOYEE_INACTIVE_LIST_SUCCESS = 'ACTION_EMPLOYEE_INACTIVE_LIST_SUCCESS';

export const ACTION_EMPLOYEE_STATUS_LOADING = 'ACTION_EMPLOYEE_STATUS_LOADING';
export const ACTION_EMPLOYEE_STATUS_SUCCESS = 'ACTION_EMPLOYEE_STATUS_SUCCESS';

export const API_CHECK_EMPLOYEE_DEPENDENCY_LOADING = 'API_CHECK_EMPLOYEE_DEPENDENCY_LOADING';
export const API_CHECK_EMPLOYEE_DEPENDENCY_SUCCESS = 'API_CHECK_EMPLOYEE_DEPENDENCY_SUCCESS';

export function actionEmployeeList(params) {
    return (dispatch, getState) =>
        Api.get(API_EMPLOYEE_LIST + '/all').then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_LIST_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeListActive(params) {
    return (dispatch, getState) =>
        Api.get(API_EMPLOYEE_LIST + '/active').then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_ACTIVE_LIST_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeListInactive(params) {
    return (dispatch, getState) =>
        Api.get(API_EMPLOYEE_LIST + '/inactive').then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_INACTIVE_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_INACTIVE_LIST_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeStatus(params) {
    return (dispatch, getState) =>
        Api.post(API_EMPLOYEE_STATUS, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_STATUS_LOADING, { loading: false, data }));
        });
}


export function actionCheckEmployeeDependency(params) {
    return (dispatch, getState) =>
        Api.post(API_CHECK_EMPLOYEE_DEPENDENCY, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(API_CHECK_EMPLOYEE_DEPENDENCY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(API_CHECK_EMPLOYEE_DEPENDENCY_LOADING, { loading: false, data }));
        });
}