import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from 'react-icons/fi';
import { RiLayoutGridFill } from 'react-icons/ri';
import { BsTable } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UNAUTHORIZED, ERROR } from '../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import $ from "jquery";
import moment from "moment";
import { verifyPermission } from '../../../../../../utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { actionTaskList, ACTION_TASK_LIST_SUCCESS } from './action';
import _ from 'lodash';
const TaskDetails = (props) => {

    const { match } = props;
    const [taskList, setTaskList] = useState([]);
    const [selectedTemplate, setselectedTemplate] = useState('LIST')

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionTaskList({});
    }
    useEffect(initialRender, []);

    const onTaskListUpdate = () => {
        const { TaskList } = props
        if (TaskList != null) {
            var TaskListResponse = Object.assign({}, TaskList);
            if (TaskListResponse.result === true) {

                $("#progress-loader").addClass("hidden");

                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "full_name", caption: "Assign By", dataType: "string", visible: true },
                    { dataField: "assign_to_full_name", caption: "Assign To", visible: true },
                    { dataField: "task", caption: "Task Name", dataType: "string", visible: true },
                    { dataField: "description", caption: "Description", dataType: "string", visible: true },
                    { dataField: "priority", caption: "Priority", dataType: "string", visible: true },
                    { dataField: "type", caption: "Type", dataType: "string", visible: true },
                    { dataField: "delivery_date", caption: "Delivery Date", dataType: "string", visible: false },
                    { dataField: "delivery_date_formated", caption: "Delivery Date", dataType: "string", visible: true },
                    { dataField: "created_date", caption: "Create Date", dataType: "string", visible: false },
                    { dataField: "created_date_formated", caption: "Create Date", dataType: "string", visible: true },
                    { dataField: "status_name", caption: "Status", dataType: "string", visible: true },
                ]);
                setTaskList(TaskListResponse.response);
            } else {
                switch (TaskListResponse.status) {
                    case UNAUTHORIZED:
                        Swal.fire({
                            title: 'Error',
                            text: 'Sorry, Your token expired',
                            icon: 'error'
                        }).then(success => {
                            props.auth.logout()
                        })
                        break;
                    case ERROR:
                        $("#progress-loader").addClass("hidden");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onTaskListUpdate, [props.TaskList])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "full_name", caption: "Assign By", dataType: "string", visible: true },
        { dataField: "assign_to_full_name", caption: "Assign To", visible: true },
        { dataField: "task", caption: "Task Name", dataType: "string", visible: true },
        { dataField: "description", caption: "Description", dataType: "string", visible: true },
        { dataField: "priority", caption: "priority", dataType: "string", visible: true },
        { dataField: "type", caption: "Type", dataType: "string", visible: true },
        { dataField: "delivery_date", caption: "Delivery Date", dataType: "string", visible: false },
        { dataField: "delivery_date_formated", caption: "Delivery Date", dataType: "string", visible: true },
        { dataField: "created_date", caption: "Create Date", dataType: "string", visible: false },
        { dataField: "created_date_formated", caption: "Create Date", dataType: "string", visible: true },
        { dataField: "status_name", caption: "Status", dataType: "string", visible: true },
    ]);

    const renderStatusCell = (cell) => {
        console.log(cell);
        switch (cell.data.status_name) {
            case 'Open':
                return <div className={"badge badge-success round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
            case 'In Progress':
            case 'To Review':
                return <div className={"badge badge-info round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
            case 'Completed':
                return <div className={"badge badge-warning round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
            case 'Cancel':
                return <div className={"badge badge-danger round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
            default:
                return <div className={"badge badge-info round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
        }
    }

    const renderTypeCell = (cell) => {
        console.log(cell);
        return <div>{_.capitalize(cell.data.type)}</div>;
    }

    const renderCreateDate = (cell) => {
        return <div>{cell?.data?.created_date_formated != null ? moment(cell?.data?.created_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderDeliveryDate = (cell) => {
        return <div>{cell?.data?.delivery_date_formated != null ? moment(cell?.data?.delivery_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderPriority = (cell) => {
        return <div>{cell?.data?.user_request?.priority != null ? (cell?.data?.user_request?.priority ) : '-'}</div>
    }
    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        {
                            taskList != null && taskList.length > 0 ?
                                <li class="nav-item mr-1">
                                    <button className="btn btn-primary btn-md font-small-3 shadow round"
                                        onClick={(event) => {
                                            if (selectedTemplate === "LIST") {
                                                setselectedTemplate("GRID")
                                            } else {
                                                setselectedTemplate("LIST")
                                            }
                                        }}
                                    >
                                        {
                                            selectedTemplate === "LIST" ?
                                                <RiLayoutGridFill />

                                                : <BsTable />
                                        }
                                    </button>
                                </li>
                                : <></>
                        }
                        {
                            verifyPermission("CREATE_TASK") ?
                                <li class="nav-item">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            props.history.push(`${match.url}/create-task`);
                                        }}
                                    >
                                        Create Task
                                    </button>
                                </li>
                                :
                                <></>
                        }
                    </ul>
                </div>
            </>
        )

    }
    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("VIEW_TASK") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else {
                if (objColumn.dataField === "status_name") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "type") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderTypeCell}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "created_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderCreateDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "delivery_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderDeliveryDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }else if (objColumn.dataField === "priority") {
                    arrColumns.push(
                        <Column
                            dataField="user_request.priority"
                            caption={objColumn.caption}
                            cellRender={renderPriority}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }

            }
        });
        return arrColumns;
    };

    const renderListUI = () => {
        return (
            <DataGrid
                dataSource={taskList}
                columnAutoWidth={true}
                showBorders={true}
                export={{
                    fileName: "Task Details",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
                onExporting={(event) => {
                    event.component.beginUpdate();
                    event.component.columnOption("created_date_formated", "visible", false)
                    event.component.columnOption("created_date", "visible", true)
                    event.component.columnOption("delivery_date_formated", "visible", false)
                    event.component.columnOption("delivery_date", "visible", true)
                    event.component.columnOption("priority", "visible", true)
                }}
                onExported={(event) => {
                    event.component.columnOption("created_date_formated", "visible", true)
                    event.component.columnOption("created_date", "visible", false)
                    event.component.columnOption("delivery_date_formated", "visible", true)
                    event.component.columnOption("delivery_date", "visible", false)
                    event.component.columnOption("priority", "visible", true)
                    event.component.endUpdate();
                }}
                onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                        location: 'before',
                        // template: 'totalGroupCount',

                    })
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }
    const renderGridUI = () => {
        var arrGridColumns = []
        if (taskList != null && taskList.length > 0) {
            taskList.map((taskList, index) => (
                arrGridColumns.push(
                    <div className="col-12 col-md-6 col-lg-6" key={index}>
                        <div className="card border">
                            <div className={`card-body px-0 py-1`}>
                                <div className="d-flex flex-row px-1">
                                    <div className="profile-img-container-sm bg-light rounded-circle mr-1 border">
                                        <h6 class="name-preffix-sm"> {String(taskList.full_name).substring(0, 2).toUpperCase()}</h6>
                                    </div>
                                    <div>
                                        <div>
                                            <p className={`card-text-esc font-medium-1 mb-0 text-capitalize`}>{taskList.full_name}</p>
                                        </div>
                                        <div className="font-small-1">
                                            <span>Assign To:</span> {taskList.assign_to_full_name}
                                        </div>
                                    </div>
                                    <div className={`ml-auto font-small-2`}>
                                        <div>
                                            <span>Delivery Date:</span> {taskList.delivery_date}
                                        </div>
                                        <div>
                                            <span>{taskList.status}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className={`my-half`} />
                                <div className="d-flex flex-row px-1">
                                    <div className={"flex-column w-50"}>
                                        <p className={`card-text-esc font-small-3 mb-0 text-capitalize`}>Task Name</p>
                                        <p className={`font-small-2 small mb-0 card-text-esc`}>{taskList.task}</p>
                                    </div>
                                    <div className={"flex-column w-50"}>
                                        <p className={`card-text-esc font-small-3 mb-0 text-capitalize`}>Task Type</p>
                                        <p className={`font-small-2 small mb-0 card-text-esc`}>{taskList.type}</p>
                                    </div>
                                    <div className={"align-self-end d-inline-flex"}>
                                        <FiEye size={14} className={`primary`} cursor="pointer"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: taskList.id } })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            ))
        }
        return arrGridColumns
    }
    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Task List {taskList.length === 0 ? '' : `(${taskList.length})`}</h5>
                </div>
                {renderHeaderButtonUI()}
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            {
                                selectedTemplate === "LIST" ?
                                    renderListUI()
                                    : <></>
                            }
                        </div>
                        {
                            selectedTemplate === "GRID" ?
                                renderGridUI()
                                : <></>
                        }


                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    var TaskList = null;
    if (
        state.TaskListReducer != null &&
        Object.keys(state.TaskListReducer).length > 0 &&
        state.TaskListReducer.constructor === Object
    ) {
        switch (state.TaskListReducer.tag) {
            case ACTION_TASK_LIST_SUCCESS:
                TaskList = Object.assign({}, state.TaskListReducer.data);
                delete state.TaskListReducer;
                return { TaskList };
            default:
        }
    }
    return {
        TaskList
    };
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTaskList }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TaskDetails))