import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { UNAUTHORIZED } from "../../../../../constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../utils/preferences';
import { verifyPermission } from "../../../../../utils";
import Swal from "sweetalert2";
import { ACTION_POLICY_DOCUMENT_HISTORY_SUCCESS, actionPolicyHistory } from "../add/action";

const UploadDocumentList = (props) => {
    const { match } = props;
    const [uploadDocList, setUploadDocList] = useState([]);
    const [columns, setColumns] = useState([
        { dataField: "id", caption: "ID" },
        { dataField: "control_no", caption: "Control No" },
        { dataField: "type", caption: "Type" },
        { dataField: "created_at_formated", caption: "Date" },

    ]);
    

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionPolicyHistory();
    }
    useEffect(initialRender, []);

    

    
    
    const renderColumns = () => {
        return columns.map((objColumn, indexColumn) => (
            <Column
                key={indexColumn} // Ensure each Column has a unique key
                dataField={objColumn.dataField}
                caption={objColumn.caption}
            />
        ));
    };
    
    const onUploadDocumentListUpdate = () => {
        const { UploadDocumentList } = props
        if (UploadDocumentList != null) {
            var UploadDocumentListResponse = Object.assign({}, UploadDocumentList);
            if (UploadDocumentListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                setUploadDocList(UploadDocumentListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (UploadDocumentListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onUploadDocumentListUpdate, [props.UploadDocumentList])
    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Upload Document History</h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                        <ul class="nav nav-pills float-right">
                            {/* {
                                verifyPermission("MASTER_DEPARTMENT_ADD") ? */}
                            <li class="nav-item">
                                <button
                                    className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                    onClick={() => {
                                        props.history.push(`${match.url}/add`);
                                    }}
                                >
                                    Upload Document
                                </button>
                            </li>
                            {/* :
                                    <></>
                            } */}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                        <div className="card-body">
                      <DataGrid
                        dataSource={uploadDocList}
                        showBorders={true}
                        columnAutoWidth={true}
                        export={{
                          fileName: "Travel List Setting",
                          enabled: true,
                          allowExportSelectedData: false,
                          excelFilterEnabled: true,
                        }}
                      >
                        <SearchPanel visible={true} />
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        {renderColumns()}
                        <Pager
                          visible={true}
                          showPageSizeSelector={false}
                          showNavigationButtons={true}
                          allowedPageSizes={[4, 6, 10]}
                        />
                        <Paging
                          defaultPageIndex={0}
                          defaultPageSize={10}
                          enabled={true}
                        />
                      </DataGrid>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    var UploadDocumentList = null;
    if (
        state.UploadPolicyDocumentReducer != null &&
        Object.keys(state.UploadPolicyDocumentReducer).length > 0 &&
        state.UploadPolicyDocumentReducer.constructor === Object
    ) {
        switch (state.UploadPolicyDocumentReducer.tag) {
            case ACTION_POLICY_DOCUMENT_HISTORY_SUCCESS:
                UploadDocumentList = Object.assign({}, state.UploadPolicyDocumentReducer.data);
                delete state.UploadPolicyDocumentReducer;
                return { UploadDocumentList };
           

        }
    }
    return {
        UploadDocumentList,
        
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({actionPolicyHistory}, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(UploadDocumentList))