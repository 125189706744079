import { useCallback, useEffect, useState } from "react";
import $ from 'jquery';
import DataGrid, { Button, Column, FilterRow, Grouping, HeaderFilter, Pager, Paging, Summary, SearchPanel, FilterPanel } from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
// import EditProductSpecialist from "../../../../../component/popups/edit_productspecialist";
import { ERROR, UNAUTHORIZED } from "../../../../../constants";
import { actionProposalPoolList, ACTION_PROPOSALPOOL_LIST_SUCCESS, actionTeamList, ACTION_LIST_TEAM_VERTICAL_SUCCESS, ACTION_ADD_POLICY_SUCCESS, actionAddPolicy, ACTION_DATE_TYPE_SUCCESS, actionDateType, ACTION_OPPORTUNITY_BULK_UPDATE_SUCCESS, actionProposalBulkUpdate } from "./action";
import { bindActionCreators } from "redux";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CalculateProposalMonth, getDateFilter, verifyPermission } from "../../../../../utils";
import OpportunityPoolDetails from "../../../../../component/popups/opportunity_pool";
import { Controller, useForm } from "react-hook-form";
import PolicyDetailModal from "../../../../../component/form/policy_details";
import moment from "moment";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../utils/preferences';
import { CheckBox, DateBox } from "devextreme-react";
import { TagBox } from "devextreme-react";
import ArrayStore from 'devextreme/data/array_store';
import ErrorLabel from "../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import OpportunityFilter from "./opportunity_filter";
import PoolBulkAction from "../../../../../component/popups/pool_bulk_action";

const ProposalPoolList = (props) => {
    const { register, control, formState: { errors }, setValue } = useForm({});
    const [proposalType, setProposalType] = useState(props?.location?.state?.type === 'UNASSIGNED_PROPOSAL' ? 'unassigned' : 'all');
    const [proposalPoolList, setProposalPoolList] = useState([])
    const [showOpportunityPoolFilter, setShowOpportunityPoolFilter] = useState(false)
    const [selectedPolicyValue, setSelectedPolicyValue] = useState([]);
    const [showOpportunityPoolDetails, setShowOpportunityPoolDetails] = useState(false);
    const [objPolicyType, setObjPolicyType] = useState([]);
    const [objCompanyName, setObjCompanyName] = useState([]);
    const [arrCompanyName, setArrCompanyName] = useState([]);
    const [objDepartment, setObjDepartment] = useState([]);
    const [arrSalesRm, setArrSalesRm] = useState([]);
    const [objSalesRM, setObjSalesRM] = useState([]);
    const [objRetentionRM, setObjRetentionRM] = useState([]);
    const [minProbability, setMinProbability] = useState(null);
    const [maxProbability, setMaxProbability] = useState(null);
    const [maxPremium, setMaxPremium] = useState(null);
    const [minPremium, setMinPremium] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedClientType, setSelectedClientType] = useState(null);
    const [objIndustrialSegment, setObjIndustrialSegment] = useState();
    const [opportunityPoolDetails, setOpportunityPoolDetails] = useState(null);
    const [dateType, setDateType] = useState('opportunity_create_date');
    const [isWithTeam, setIsWithTeam] = useState(1);
    const [isResetClicked, setIsResetClicked] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedRmName, setSelectedRMName] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState([]);
    const [isMyData, setMyData] = useState(1);
    const [showPolicyDetails, setShowPolicyDetails] = useState(false)
    const [myTeamMembers, setMyTeamMembers] = useState([]);
    const [selectedCheckUserId, setSelectedCheckUserId] = useState();
    const [dateFilterDropdown, setDateFilterDropdown] = useState([]);
    const [opportunityPool, setOpportunityPool] = useState(false);
    const [proposalPoolListCount, setProposalPoolListCount] = useState(0);
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [fromDate, setFromDate] = useState(moment().month() < 3
        ? moment()
            .month(3)
            .startOf("month")
            .subtract(1, "years")
            .format("YYYY-MM-DD")
        : moment()
            .month(3)
            .startOf("month")
            .format("YYYY-MM-DD"));
    const [displaySelectedFormatBtn, setDisplaySelectedFormatBtn] = useState(null);
    let day = Object.assign({}, getDateFilter('DAY'))
    let reset = Object.assign({}, getDateFilter('RESET_OPPORTUNITY'))
    const [opportunityTypeValue, setOpportunityTypeValue] = useState([]);
    const [departmentIdValue, setDepartmentIdValue] = useState([]);
    const [policyTypeValue, setPolicyTypeValue] = useState([]);
    const [expectedMinValue, setExpectedMinValue] = useState();
    const [expectedMaxValue, setExpectedMaxValue] = useState();
    const [minProbabilityValue, setMinProbabilityValue] = useState();
    const [maxProbabilityValue, setMaxProbabilityValue] = useState();
    const [clientTypeValue, setClientTypeValue] = useState();
    const [categoryValue, setCategoryValue] = useState();
    const [industrialValue, setIndustrialValue] = useState();
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [selectedRows, setSelectedRows] = useState(new Set());

    const handleRowSelect = useCallback(
        (id) => {
            setSelectedRows((prevSelected) => {
                const updatedSelected = new Set(prevSelected);
                if (updatedSelected.has(id)) {
                    updatedSelected.delete(id);
                } else {
                    updatedSelected.add(id);
                }
                return updatedSelected;
            });
        },
        []
    );

    const handleSelectAll = useCallback(() => {
        setSelectedRows((prevSelected) =>
            prevSelected.size === proposalPoolList.length ? new Set() : new Set(proposalPoolList.map((row) => row.id))
        );
    }, [proposalPoolList.length]);

    const columns = [
        { dataField: "Select", caption: "Select", type: "button", visible: false },
        { dataField: "Action", caption: "Action", type: "button", visible: false },
        { dataField: "id", caption: "ID", visible: true },
        { dataField: "created_at", caption: "Created Date", visible: true },
        { dataField: "created_by_branch_city", caption: "Branch", visible: true },
        { dataField: "client_type", caption: "Client Type", visible: false },
        { dataField: "oppurtunity_type", caption: "Opportunity Type", visible: true },
        { dataField: "department_name", caption: "Department", visible: true },
        { dataField: "policy_type", caption: "Policy Type", visible: true },
        { dataField: "company_group_name", caption: "Company Group Name", visible: false },
        { dataField: "customer_name", caption: "Company Name", visible: true },
        { dataField: "expiring_date", caption: "Expiry Date", visible: false },
        { dataField: "formated_expiring_date", caption: "Expiry Date", visible: true },
        // { dataField: "proposal_month", caption: "Proposal Month", visible: true },
        { dataField: "expected_premium", caption: "Renewal Premium", dataType: 'number', visible: true },
        { dataField: "ps_name", caption: "Product RM", visible: proposalType != 'unassigned' ? true : false },
        { dataField: "retention_rm_name", caption: "Retention RM", visible: true },
        { dataField: "assigned_rm_name", caption: "Sales RM", visible: true },
        { dataField: "probability", caption: "Probability", visible: true },
        { dataField: "opportunity_status", caption: "Status", visible: true },
        { dataField: "closure_type", caption: "Closure Type", visible: false },
        { dataField: "success_premium_amount", caption: "Premium Amount(Quote Amt (net))", visible: false },
        // { dataField: "success_pinc_share", caption: "Pinc Share", visible: false },
        // { dataField: "total_share_premium", caption: "Total Share Premium Amount", visible: false },
        { dataField: "success_insurer_name", caption: "Insurer Name", visible: false },
        { dataField: "opportunity_sub_status", caption: "Sub Status", visible: false },
        // { dataField: "rfp_insure_count", caption: "RFP Insurer Count", visible: false },
        { dataField: "success_instalment", caption: "Instalment", visible: false },
        { dataField: "success_policy_start_date", caption: "Policy Start Date", visible: false },
        { dataField: "formated_end_date", caption: "Policy End Date", visible: false },
        { dataField: "lost_intermediary_type", caption: "Competition Intermediary Type", visible: false },
        { dataField: "lost_intermediary_name", caption: "Intermediary Name", visible: false },
        { dataField: "lost_insurer_name", caption: "Insurer Name", visible: false },
        { dataField: "lost_net_amount", caption: "Net Amount", visible: false },
        { dataField: "opportunity_remark", caption: "Placement Remark", visible: false },
        { dataField: "updated_at", caption: "Last Modified Date", visible: false },
        { dataField: "segment_name", caption: "Client Industry", visible: false },
        // { dataField: "last_meeting_date", caption: "Last Meeting Date", visible: false },
        { dataField: "created_by", caption: "Created By", visible: true },
        { dataField: "created_at", caption: "Created Date", visible: false },
        { dataField: "category", caption: "Client Category", visible: false },
        { dataField: "client_created_at", caption: "Lead/Client Created date", visible: false },
    ];

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionTeamList({ user_id: props.auth.getUser().user_id });
        props.actionDateType()
        if (props?.location?.state?.proposalType != null && props?.location?.state?.proposalType != undefined) {
            setProposalType(props?.location?.state?.proposalType)
        }
    }
    useEffect(initialRender, []);

    // const renderProposalMonthCell = (cell) => {
    //     if (cell?.data?.expiring_date != null) {
    //     return (
    //         <span>{CalculateProposalMonth(moment(cell?.data?.expiring_date, 'DD/MM/YYYY'))}</span>
    //     );
    // }
    // };


    const renderCompanyNameCell = (cell) => {
        if (cell?.data?.customer_name != null) {
            return <a
                className='text-primary'
                onClick={(() => {
                    if (cell?.data?.client_type === 'lead') {
                        props.history.push({
                            pathname: '/leads/view',
                            state: {
                                client_id: cell?.data?.client_id,
                            }
                        });
                    } else if (cell?.data?.client_type === 'client') {
                        props.history.push({
                            pathname: '/client/view',
                            state: {
                                client_id: cell?.data?.client_id,
                            }
                        });
                    }
                })}
            ><u>{cell?.data?.customer_name}</u></a>
        }
    }

    const renderProfileLink = (cellData, userIdField, nameField) => {
        if (cellData?.data?.[userIdField] != null) {
            return (
                <a
                    className='text-primary'
                    onClick={() => {
                        props.history.push({
                            pathname: '/profile',
                            state: {
                                user_id: cellData?.data?.[userIdField],
                            }
                        })
                    }}
                >
                    <u>{cellData?.data?.[nameField]}</u>
                </a>
            );
        }
    }

    const onListTeamVerticalUpdate = () => {
        const { ListTeamVertical } = props;
        var ListTeamVerticalResponse = Object.assign({}, ListTeamVertical);
        if (ListTeamVerticalResponse.result === true) {
            var myTeam = []
            var myTeamMember = []
            ListTeamVerticalResponse.response.map((item) => {
                if (item.id != props.auth.getUser().user_id) {
                    myTeam.push(item);
                    myTeamMember.push(item.id);
                    // setMyTeamVertical(myTeam);
                    setMyTeamMembers(myTeamMember);
                }
                $("#progress-loader").removeClass("hidden");
                const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                setSelectedCheckUserId(updatedUserIds)
                if (Number(ListTeamVerticalResponse.response.length) < 0) {
                    props.actionProposalPoolList({
                        from_date: fromDate,
                        to_date: toDate,
                        type: proposalType,
                        user_ids: updatedUserIds,
                        // new filters
                        date_type: dateType,
                        opportunity_type: selectedPolicyValue,
                        city_state_master_id: objCompanyName,
                        assigned_rm_ids: objSalesRM,
                        department_ids: objDepartment,
                        policy_type_ids: objPolicyType,
                        min_expected_premium: Number(minPremium),
                        max_expected_premium: Number(maxPremium),
                        min_probability: Number(minProbability),
                        max_probability: Number(maxProbability),
                        client_type: selectedClientType,
                        category: selectedCategory,
                        industrial_segment_id: objIndustrialSegment,
                        retention_rm_name:objRetentionRM
                    })
                }
            })
        } else {
            switch (ListTeamVerticalResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onListTeamVerticalUpdate, [props.ListTeamVertical]);

    useEffect(() => {
        $("#progress-loader").removeClass("hidden");
        const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
        setSelectedCheckUserId(updatedUserIds)
        props.actionProposalPoolList({
            from_date: fromDate,
            to_date: toDate,
            type: proposalType,
            user_ids: updatedUserIds,
            // new filters
            date_type: dateType,
            opportunity_type: selectedPolicyValue,
            city_state_master_id: objCompanyName,
            assigned_rm_ids: objSalesRM,
            department_ids: objDepartment,
            policy_type_ids: objPolicyType,
            min_expected_premium: Number(minPremium),
            max_expected_premium: Number(maxPremium),
            min_probability: Number(minProbability),
            max_probability: Number(maxProbability),
            client_type: selectedClientType,
            category: selectedCategory,
            industrial_segment_id: objIndustrialSegment,
            retention_rm_name:objRetentionRM

        })
    }, [myTeamMembers])

    const onDateFilterUpdate = () => {
        const { DateFilter } = props;
        var DateFilterResponse = Object.assign({}, DateFilter);
        if (DateFilterResponse.result === true) {
            setDateFilterDropdown(DateFilterResponse?.response?.opportunity_date_type_filter)
        } else {
            switch (DateFilterResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(DateFilterResponse.message);
                default:
            }
        }
    }
    useEffect(onDateFilterUpdate, [props.DateFilter]);

    useEffect(() => {
        setDateType('opportunity_create_date')
        setValue('date_type', 'opportunity_create_date')
    }, [dateFilterDropdown])

    const onAddPolicyUpdate = () => {
        const { AddPolicy } = props;
        if (AddPolicy != null) {
            var AddPolicyResponse = Object.assign({}, AddPolicy);
            if (AddPolicyResponse.result === true) {
                toast.success('Opportunity added successfully')
                $("#progress-loader").removeClass("hidden");
                const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                setSelectedCheckUserId(updatedUserIds)
                props.actionProposalPoolList({
                    from_date: fromDate,
                    to_date: toDate,
                    type: proposalType,
                    user_ids: updatedUserIds,
                    // new filters
                    date_type: dateType,
                    opportunity_type: selectedPolicyValue,
                    city_state_master_id: objCompanyName,
                    assigned_rm_ids: objSalesRM,
                    department_ids: objDepartment,
                    policy_type_ids: objPolicyType,
                    min_expected_premium: Number(minPremium),
                    max_expected_premium: Number(maxPremium),
                    min_probability: Number(minProbability),
                    max_probability: Number(maxProbability),
                    client_type: selectedClientType,
                    category: selectedCategory,
                    industrial_segment_id: objIndustrialSegment,
                    retention_rm_name:objRetentionRM
                })
            } else {
                switch (AddPolicyResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onAddPolicyUpdate, [props.AddPolicy]);

    const onProposalPoolUpdate = () => {

        const { ProposalPool } = props;

        if (ProposalPool != null) {

            var ProposalPoolResponse = Object.assign({}, ProposalPool);

            if (ProposalPoolResponse.result === true) {
                setProposalPoolList(ProposalPoolResponse.response);
                setProposalPoolListCount(ProposalPoolResponse.response.length);
                var arrCompanyName = [];
                var uniqueCompanyName = new Set();
                ProposalPoolResponse.response.forEach((element) => {
                    if (!uniqueCompanyName.has(element.created_by_branch_city_id)) {
                        var selected = objCompanyName != null ? objCompanyName.includes(element.created_by_branch_city) : false;
                        arrCompanyName.push({ value: element.created_by_branch_city_id, title: element.created_by_branch_city, selected: selected });
                        uniqueCompanyName.add(element.created_by_branch_city_id);
                    } else {
                    }
                });
                arrCompanyName.sort((a, b) => a?.title?.localeCompare(b?.title));
                setArrCompanyName(arrCompanyName);
                var arrSalesRM = [];
                var uniqueSalesRM = new Set();
                ProposalPoolResponse.response.forEach((element) => {
                    if (element.assigned_rm_id !== null && !uniqueSalesRM.has(element.assigned_rm_id)) {
                        var selected = objSalesRM != null ? objSalesRM.includes(element.assigned_rm_name) : false;
                        arrSalesRM.push({ value: element.assigned_rm_id, title: element.assigned_rm_name, selected: selected });
                        uniqueSalesRM.add(element.assigned_rm_id);
                    } else {
                    }
                });
                arrSalesRM.sort((a, b) => a?.title?.localeCompare(b?.title));
                setArrSalesRm(arrSalesRM);

                $("#progress-loader").addClass("hidden");

                // setTimeout(() => {
                //     $("#progress-loader").addClass("hidden");
                // }, 5500)
            } else {
                $("#progress-loader").addClass("hidden");
                setProposalPoolList([])
                switch (ProposalPoolResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout()
                        break;
                    case ERROR:
                        setProposalPoolList(0)
                        toast.error(ProposalPoolResponse.message);
                    default:
                }
            }

        }
    }
    useEffect(onProposalPoolUpdate, [props.ProposalPool]);

    useEffect(() => {
        if (isWithTeam == 1 && isMyData == 1) {
            const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
            setSelectedCheckUserId(updatedUserIds)
            $("#progress-loader").removeClass("hidden");
            props.actionProposalPoolList({
                from_date: fromDate,
                to_date: toDate,
                type: proposalType,
                user_ids: updatedUserIds,
                // Other Filters
                date_type: dateType,
                opportunity_type: selectedPolicyValue,
                city_state_master_id: objCompanyName,
                assigned_rm_ids: objSalesRM,
                department_ids: objDepartment,
                policy_type_ids: objPolicyType,
                min_expected_premium: Number(minPremium),
                max_expected_premium: Number(maxPremium),
                min_probability: Number(minProbability),
                max_probability: Number(maxProbability),
                client_type: selectedClientType,
                category: selectedCategory,
                industrial_segment_id: objIndustrialSegment,
                retention_rm_name:objRetentionRM

            })
        } else if (isWithTeam == 1) {
            setSelectedCheckUserId(myTeamMembers)
            $("#progress-loader").removeClass("hidden");
            props.actionProposalPoolList({
                from_date: fromDate,
                to_date: toDate,
                type: proposalType,
                user_ids: myTeamMembers,
                // Other Filters
                date_type: dateType,
                opportunity_type: selectedPolicyValue,
                city_state_master_id: objCompanyName,
                assigned_rm_ids: objSalesRM,
                department_ids: objDepartment,
                policy_type_ids: objPolicyType,
                min_expected_premium: Number(minPremium),
                max_expected_premium: Number(maxPremium),
                min_probability: Number(minProbability),
                max_probability: Number(maxProbability),
                client_type: selectedClientType,
                category: selectedCategory,
                industrial_segment_id: objIndustrialSegment,
                retention_rm_name:objRetentionRM

            })
        } else if (isMyData == 1) {
            setSelectedCheckUserId([props.auth.getUser().user_id])
            $("#progress-loader").removeClass("hidden");
            props.actionProposalPoolList({
                from_date: fromDate,
                to_date: toDate,
                type: proposalType,
                user_ids: [props.auth.getUser().user_id],
                // Other Filters
                date_type: dateType,
                opportunity_type: selectedPolicyValue,
                city_state_master_id: objCompanyName,
                assigned_rm_ids: objSalesRM,
                department_ids: objDepartment,
                policy_type_ids: objPolicyType,
                min_expected_premium: Number(minPremium),
                max_expected_premium: Number(maxPremium),
                min_probability: Number(minProbability),
                max_probability: Number(maxProbability),
                client_type: selectedClientType,
                category: selectedCategory,
                industrial_segment_id: objIndustrialSegment,
                retention_rm_name:objRetentionRM

            })
        } else if (!isMyData == 1 && isWithTeam == 1) {
            setSelectedCheckUserId(myTeamMembers)
            $("#progress-loader").removeClass("hidden");
            props.actionProposalPoolList({
                from_date: fromDate,
                to_date: toDate,
                type: proposalType,
                user_ids: myTeamMembers,
                // Other Filters
                date_type: dateType,
                opportunity_type: selectedPolicyValue,
                city_state_master_id: objCompanyName,
                assigned_rm_ids: objSalesRM,
                department_ids: objDepartment,
                policy_type_ids: objPolicyType,
                min_expected_premium: Number(minPremium),
                max_expected_premium: Number(maxPremium),
                min_probability: Number(minProbability),
                max_probability: Number(maxProbability),
                client_type: selectedClientType,
                category: selectedCategory,
                industrial_segment_id: objIndustrialSegment,
                retention_rm_name:objRetentionRM

            })
        } else if (isMyData == 1 && !isWithTeam == 1) {
            setSelectedCheckUserId([props.auth.getUser().user_id])
            $("#progress-loader").removeClass("hidden");
            props.actionProposalPoolList({
                from_date: fromDate,
                to_date: toDate,
                type: proposalType,
                user_ids: [props.auth.getUser().user_id],
                // Other Filters
                date_type: dateType,
                opportunity_type: selectedPolicyValue,
                city_state_master_id: objCompanyName,
                assigned_rm_ids: objSalesRM,
                department_ids: objDepartment,
                policy_type_ids: objPolicyType,
                min_expected_premium: Number(minPremium),
                max_expected_premium: Number(maxPremium),
                min_probability: Number(minProbability),
                max_probability: Number(maxProbability),
                client_type: selectedClientType,
                category: selectedCategory,
                industrial_segment_id: objIndustrialSegment,
                retention_rm_name:objRetentionRM

            })
        } else if (!isMyData == 1 && !isWithTeam == 1) {
            setSelectedCheckUserId([props.auth.getUser().user_id])
            $("#progress-loader").removeClass("hidden");
            props.actionProposalPoolList({
                from_date: fromDate,
                to_date: toDate,
                type: proposalType,
                user_ids: [props.auth.getUser().user_id],
                // Other Filters
                date_type: dateType,
                opportunity_type: selectedPolicyValue,
                city_state_master_id: objCompanyName,
                assigned_rm_ids: objSalesRM,
                department_ids: objDepartment,
                policy_type_ids: objPolicyType,
                min_expected_premium: Number(minPremium),
                max_expected_premium: Number(maxPremium),
                min_probability: Number(minProbability),
                max_probability: Number(maxProbability),
                client_type: selectedClientType,
                category: selectedCategory,
                industrial_segment_id: objIndustrialSegment,
                retention_rm_name:objRetentionRM

            })
        }
    }, [isWithTeam, isMyData])

    const onPolicyBulkUpdate = () => {
        const { PolicyBulkUpdate } = props;
        if (PolicyBulkUpdate != null) {
            var PolicyBulkUpdateResponse = Object.assign({}, PolicyBulkUpdate);
            if (PolicyBulkUpdateResponse.result === true) {
                toast.success('Opportunity updated successfully')
                setSelectedRows(new Set());
                const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                setSelectedCheckUserId(updatedUserIds)
                props.actionProposalPoolList({
                    from_date: fromDate,
                    to_date: toDate,
                    type: proposalType,
                    user_ids: updatedUserIds,
                    // new filters
                    date_type: dateType,
                    opportunity_type: selectedPolicyValue,
                    city_state_master_id: objCompanyName,
                    assigned_rm_ids: objSalesRM,
                    department_ids: objDepartment,
                    policy_type_ids: objPolicyType,
                    min_expected_premium: Number(minPremium),
                    max_expected_premium: Number(maxPremium),
                    min_probability: Number(minProbability),
                    max_probability: Number(maxProbability),
                    client_type: selectedClientType,
                    category: selectedCategory,
                    industrial_segment_id: objIndustrialSegment
                })
            } else {
                $("#progress-loader").addClass("hidden");
                switch (PolicyBulkUpdateResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onPolicyBulkUpdate, [props.PolicyBulkUpdate]);

    const renderDate = (cell) => {
        return <div>{cell?.data?.formated_expiring_date != null ? moment(cell?.data?.formated_expiring_date).format('DD/MM/YYYY') : ''}</div>
    }
    const renderCreatedDate = (cell) => {
        return <div>{cell?.data?.formated_created_at != null ? moment(cell?.data?.formated_created_at).format('DD/MM/YYYY') : ''}</div>
    }
    const renderRetentionRm = (cell) => {
        return <div>{cell?.data?.retention_rm_name != null ? (cell?.data?.retention_rm_name) : '-'}</div>
    }
    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("OPPORTUNITY_POOL_VIEW") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {

                                                localStorage.setItem('detailsData', JSON.stringify(params.data));

                                                const url = `/proposal_pool/view?client_id=${params.data.id}&type=${proposalType}`;
                                                const win = window.open(url, '_blank');
                                                if (win) {
                                                    win.focus();
                                                } else {
                                                    console.log('Pop-up blocked. Please allow pop-ups for this site.');
                                                }
                                                setOpportunityPoolDetails(params.data);
                                                // }
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("OPPORTUNITY_POOL_UPDATE") ?
                                <Button
                                    render={(params) => (
                                        params.data.editable === 'yes' ? (
                                            <button
                                                className="btn btn-icon btn-sm"
                                                onClick={() => {

                                                    localStorage.setItem('detailsData', JSON.stringify(params.data));

                                                    const url = `/proposal_pool/edit?id=${params.data.id}&type=${proposalType}`;
                                                    const win = window.open(url, '_blank');
                                                    if (win) {
                                                        win.focus();
                                                    } else {
                                                        console.log('Pop-up blocked. Please allow pop-ups for this site.');
                                                    }
                                                }}
                                            >
                                                <FiEdit size={16} className="primary" />
                                            </button>
                                        ) : (
                                            <></>
                                        )
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "Select" && (proposalType === "list_bulk_renewal" || proposalType === "list_bulk_quote_reject")) {
                arrColumns.push(
                    <Column dataField={"Select"} type="buttons" caption={"Select"}> {
                        <Button
                            render={(params) => (
                                <input
                                    type="checkbox"
                                    checked={selectedRows.has(params.data.id)}
                                    onChange={() => handleRowSelect(params.data.id)}
                                    aria-label={`Select`}
                                />
                            )}
                        />
                    }
                    </Column>
                );
            }
            else if (objColumn.dataField === "formated_expiring_date") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else if (objColumn.dataField === "assigned_rm_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={(cell) => renderProfileLink(cell, 'assigned_rm_id', 'assigned_rm_name')}
                        visible={objColumn.visible}
                    />
                );
            }
            else if (objColumn.dataField === "ps_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={(cell) => renderProfileLink(cell, 'product_specialist_id', 'ps_name')}
                        visible={objColumn.visible}
                    />
                );
            }
            else if (objColumn.dataField === "formated_created_at") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCreatedDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            
            } else if (objColumn.dataField === "customer_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCompanyNameCell}
                    ></Column>
                );
                
            // }  else if (objColumn.dataField === "proposal_month") {
            //     arrColumns.push(
            //         <Column
            //             dataField={objColumn.dataField}
            //             caption={objColumn.caption}
            //             cellRender={renderProposalMonthCell}
            //         ></Column>
            //     );
            } else if (objColumn.dataField === "retention_rm_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderRetentionRm}
                        visible={objColumn.visible}
                        
                    ></Column>
                );
            
            } 
            else  {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const handleBulkUpdate = (data) => {
        $("#progress-loader").removeClass("hidden");
        props.actionProposalBulkUpdate(data);
    }

    function cleanObject(obj) {
        return Object.fromEntries(
            Object.entries(obj)
                .filter(([_, v]) => v !== undefined && v !== "" && v !== null)
        );
    }

    return (
        <>
            <div className="row mb-1">
                <div className="content-header-left col-12 col-md-10 col-lg-10 d-flex align-items-center">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        <li class="nav-item mb-1">
                            <button
                                onClick={() => {
                                    setSelectedRows(new Set());
                                    setProposalType("all");
                                    setProposalPoolList([])
                                    setProposalPoolListCount(0)
                                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                    setSelectedCheckUserId(updatedUserIds)
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionProposalPoolList({
                                        from_date: fromDate,
                                        to_date: toDate,
                                        type: "all",
                                        user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                        // Other Filters
                                        date_type: dateType,
                                        opportunity_type: selectedPolicyValue,
                                        city_state_master_id: objCompanyName,
                                        assigned_rm_ids: objSalesRM,
                                        department_ids: objDepartment,
                                        policy_type_ids: objPolicyType,
                                        min_expected_premium: Number(minPremium),
                                        max_expected_premium: Number(maxPremium),
                                        min_probability: Number(minProbability),
                                        max_probability: Number(maxProbability),
                                        client_type: selectedClientType,
                                        category: selectedCategory,
                                        industrial_segment_id: objIndustrialSegment,
                                        retention_rm_name:objRetentionRM
                                    });
                                }}
                                className={`btn ${proposalType === 'all' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                Total Opportunity
                                <span className="ml-half">{proposalType === 'all' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}</span>
                            </button>
                        </li>
                        <li class="nav-item ml-1 mb-1">
                            <button
                                onClick={() => {
                                    setSelectedRows(new Set());
                                    setProposalPoolList([])
                                    setProposalPoolListCount(0)
                                    setProposalType("unassigned");
                                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                    setSelectedCheckUserId(updatedUserIds)
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionProposalPoolList({
                                        from_date: fromDate,
                                        to_date: toDate,
                                        type: "unassigned",
                                        user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],

                                        // Other Filters
                                        date_type: dateType,
                                        opportunity_type: selectedPolicyValue,
                                        city_state_master_id: objCompanyName,
                                        assigned_rm_ids: objSalesRM,
                                        department_ids: objDepartment,
                                        policy_type_ids: objPolicyType,
                                        min_expected_premium: Number(minPremium),
                                        max_expected_premium: Number(maxPremium),
                                        min_probability: Number(minProbability),
                                        max_probability: Number(maxProbability),
                                        client_type: selectedClientType,
                                        category: selectedCategory,
                                        industrial_segment_id: objIndustrialSegment,
                                        retention_rm_name:objRetentionRM

                                    });


                                }}
                                className={`btn ${proposalType === 'unassigned' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round`}>
                                Unassigned
                                <span className="ml-half">{proposalType === 'unassigned' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}</span>
                            </button>
                        </li>
                        <li class="nav-item ml-1 mb-1">
                            <button
                                onClick={() => {
                                    setSelectedRows(new Set());
                                    setProposalPoolList([])
                                    setProposalPoolListCount(0)
                                    setProposalType("assigned");
                                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                    setSelectedCheckUserId(updatedUserIds)
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionProposalPoolList({
                                        from_date: fromDate,
                                        to_date: toDate,
                                        type: "assigned",
                                        user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                        // Other Filters
                                        date_type: dateType,
                                        opportunity_type: selectedPolicyValue,
                                        city_state_master_id: objCompanyName,
                                        assigned_rm_ids: objSalesRM,
                                        department_ids: objDepartment,
                                        policy_type_ids: objPolicyType,
                                        min_expected_premium: Number(minPremium),
                                        max_expected_premium: Number(maxPremium),
                                        min_probability: Number(minProbability),
                                        max_probability: Number(maxProbability),
                                        client_type: selectedClientType,
                                        category: selectedCategory,
                                        industrial_segment_id: objIndustrialSegment,
                                        retention_rm_name:objRetentionRM

                                    });
                                }}
                                className={`btn ${proposalType === 'assigned' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                Assigned
                                <span className="ml-half">{proposalType === 'assigned' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}</span>
                            </button>
                        </li>
                        <li class="nav-item ml-1 mb-1">
                            <button
                                onClick={() => {
                                    setSelectedRows(new Set());
                                    setProposalPoolList([])
                                    setProposalPoolListCount(0)
                                    setProposalType("quote_rejected");
                                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                    setSelectedCheckUserId(updatedUserIds)
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionProposalPoolList({
                                        from_date: fromDate,
                                        to_date: toDate,
                                        type: "quote_rejected",
                                        user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                        // Other Filters
                                        date_type: dateType,
                                        opportunity_type: selectedPolicyValue,
                                        city_state_master_id: objCompanyName,
                                        assigned_rm_ids: objSalesRM,
                                        department_ids: objDepartment,
                                        policy_type_ids: objPolicyType,
                                        min_expected_premium: Number(minPremium),
                                        max_expected_premium: Number(maxPremium),
                                        min_probability: Number(minProbability),
                                        max_probability: Number(maxProbability),
                                        client_type: selectedClientType,
                                        category: selectedCategory,
                                        industrial_segment_id: objIndustrialSegment,
                                        retention_rm_name:objRetentionRM

                                    });
                                }}
                                className={`btn ${proposalType === 'quote_rejected' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round`}>
                                Quotes Rejected
                                <span className="ml-half">{proposalType === 'quote_rejected' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}</span>
                            </button>
                        </li>
                        <li class="nav-item ml-1 mb-1">
                            <button
                                onClick={() => {
                                    setSelectedRows(new Set());
                                    setProposalPoolList([])
                                    setProposalPoolListCount(0)
                                    setProposalType("proposal_success");
                                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                    setSelectedCheckUserId(updatedUserIds)
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionProposalPoolList({
                                        from_date: fromDate,
                                        to_date: toDate,
                                        type: "proposal_success",
                                        user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                        // Other Filters
                                        date_type: dateType,
                                        opportunity_type: selectedPolicyValue,
                                        city_state_master_id: objCompanyName,
                                        assigned_rm_ids: objSalesRM,
                                        department_ids: objDepartment,
                                        policy_type_ids: objPolicyType,
                                        min_expected_premium: Number(minPremium),
                                        max_expected_premium: Number(maxPremium),
                                        min_probability: Number(minProbability),
                                        max_probability: Number(maxProbability),
                                        client_type: selectedClientType,
                                        category: selectedCategory,
                                        industrial_segment_id: objIndustrialSegment,
                                        retention_rm_name:objRetentionRM

                                    });
                                }}
                                className={`btn ${proposalType === 'proposal_success' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round`}>
                                Proposal Success
                                <span className="ml-half">{proposalType === 'proposal_success' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}</span>
                            </button>
                        </li>
                        <li class="nav-item ml-1 mb-1">
                            <button
                                onClick={() => {
                                    setSelectedRows(new Set());
                                    setProposalPoolList([])
                                    setProposalPoolListCount(0)
                                    setProposalType("proposal_lost");
                                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                    setSelectedCheckUserId(updatedUserIds)
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionProposalPoolList({
                                        from_date: fromDate,
                                        to_date: toDate,
                                        type: "proposal_lost",
                                        user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                        // Other Filters
                                        date_type: dateType,
                                        opportunity_type: selectedPolicyValue,
                                        city_state_master_id: objCompanyName,
                                        assigned_rm_ids: objSalesRM,
                                        department_ids: objDepartment,
                                        policy_type_ids: objPolicyType,
                                        min_expected_premium: Number(minPremium),
                                        max_expected_premium: Number(maxPremium),
                                        min_probability: Number(minProbability),
                                        max_probability: Number(maxProbability),
                                        client_type: selectedClientType,
                                        category: selectedCategory,
                                        industrial_segment_id: objIndustrialSegment,
                                        retention_rm_name:objRetentionRM

                                    });
                                }}
                                className={`btn ${proposalType === 'proposal_lost' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round`}>
                                Proposal Lost
                                <span className="ml-half">{proposalType === 'proposal_lost' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}</span>
                            </button>
                        </li>
                        <br></br>
                        <li class="nav-item ml-1 mb-1">
                            <button
                                onClick={() => {
                                    setSelectedRows(new Set());
                                    setProposalPoolList([])
                                    setProposalPoolListCount(0)
                                    setProposalType("list_bulk_renewal");
                                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                    setSelectedCheckUserId(updatedUserIds)
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionProposalPoolList({
                                        from_date: fromDate,
                                        to_date: toDate,
                                        type: "list_bulk_renewal",
                                        user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                        // Other Filters
                                        date_type: dateType,
                                        opportunity_type: selectedPolicyValue,
                                        city_state_master_id: objCompanyName,
                                        assigned_rm_ids: objSalesRM,
                                        department_ids: objDepartment,
                                        policy_type_ids: objPolicyType,
                                        min_expected_premium: Number(minPremium),
                                        max_expected_premium: Number(maxPremium),
                                        min_probability: Number(minProbability),
                                        max_probability: Number(maxProbability),
                                        client_type: selectedClientType,
                                        category: selectedCategory,
                                        industrial_segment_id: objIndustrialSegment
                                    });
                                }}
                                className={`btn ${proposalType === 'list_bulk_renewal' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round`}>
                                Renew Transactions
                                <span className="ml-half">{proposalType === 'list_bulk_renewal' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}</span>
                            </button>
                        </li>
                        <li class="nav-item ml-1 mb-1">
                            <button
                                onClick={() => {
                                    setSelectedRows(new Set());
                                    setProposalPoolList([])
                                    setProposalPoolListCount(0)
                                    setProposalType("list_bulk_quote_reject");
                                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                    setSelectedCheckUserId(updatedUserIds)
                                    $("#progress-loader").removeClass("hidden");
                                    props.actionProposalPoolList({
                                        from_date: fromDate,
                                        to_date: toDate,
                                        type: "list_bulk_quote_reject",
                                        user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                        // Other Filters
                                        date_type: dateType,
                                        opportunity_type: selectedPolicyValue,
                                        city_state_master_id: objCompanyName,
                                        assigned_rm_ids: objSalesRM,
                                        department_ids: objDepartment,
                                        policy_type_ids: objPolicyType,
                                        min_expected_premium: Number(minPremium),
                                        max_expected_premium: Number(maxPremium),
                                        min_probability: Number(minProbability),
                                        max_probability: Number(maxProbability),
                                        client_type: selectedClientType,
                                        category: selectedCategory,
                                        industrial_segment_id: objIndustrialSegment
                                    });
                                }}
                                className={`btn ${proposalType === 'list_bulk_quote_reject' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round`}>
                                Bulk Quote Reject
                                <span className="ml-half">{proposalType === 'list_bulk_quote_reject' && proposalPoolListCount > 0 ? `(` + proposalPoolListCount + `)` : ``}</span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="content-header-left col-12 col-md-2 col-lg-2">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    // props.history.push(`${match.url}/add`);
                                    // setEditPolicyIndex(-1)
                                    setShowPolicyDetails(true)
                                    setOpportunityPool(true)
                                }}
                            >
                                Add Opportunity
                            </button>
                        </li>
                    </ul>
                </div>
            </div>


            {/* FILTER CARD */}


            <div div className="" >
                <div className="card">
                    <div className="card-body pb-0 py-1">
                        <form noValidate autoComplete="Off" >
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <h4 className=" mb-0 fw-bold">Filters</h4>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12 col-md-2 mb-0">
                                        <div className="form-group mb-0">
                                            {/* <label className="label-control pl-0 mb-0">Date Type</label> */}
                                            <Controller
                                                name="date_type"
                                                control={control}
                                                render={({ field }) =>
                                                    <DropDownListComponent
                                                        {...field}
                                                        id="date_type"
                                                        name="date_type"
                                                        dataSource={dateFilterDropdown}
                                                        allowFiltering={false}
                                                        placeholder="Select Date Type"
                                                        value={field.value}
                                                        fields={{ text: 'name', value: 'value' }}
                                                        change={(event) => {
                                                            if (event.itemData != null) {
                                                                field.onChange(event.itemData.value)
                                                                setDateType(event.itemData.value)
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                            {/* {errors.date_type && <ErrorLabel message={errors.date_type?.message} />} */}
                                        </div>
                                    </div>
                                    {
                                        dateType != null ?
                                            <>

                                                <div className="col-12 col-md-2 mb-0">
                                                    <div className="form-group mb-0">

                                                        <Controller
                                                            name="from_date"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DateBox
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    acceptCustomValue={false}
                                                                    stylingMode="underlined"
                                                                    pickerType="calendar"
                                                                    placeholder="From Date"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    showClearButton={false}
                                                                    value={fromDate}
                                                                    onValueChange={(value) => {
                                                                        var objFromDate = new Date(value);
                                                                        var objToDate = new Date(toDate);

                                                                        if (objToDate.getTime() < objFromDate.getTime()) {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                text: "From date can't be greater than To date",
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })
                                                                        } else {

                                                                            // setDisplaySelectedFormatBtn(null);
                                                                            field.onChange(moment(value).format('yyyy-MM-DD'));
                                                                            var strFromDate = moment(value).format('yyyy-MM-DD')
                                                                            setFromDate(strFromDate);
                                                                            const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                                                            setSelectedCheckUserId(updatedUserIds)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-2 mb-0">
                                                    <div className="form-group mb-0">
                                                        <Controller
                                                            name="to_date"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DateBox
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    acceptCustomValue={false}
                                                                    stylingMode="underlined"
                                                                    pickerType="calendar"
                                                                    placeholder="To Date"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    showClearButton={false}
                                                                    value={toDate}
                                                                    onValueChange={(value) => {
                                                                        var objFromDate = new Date(fromDate);
                                                                        var objToDate = new Date(value);

                                                                        if (objToDate.getTime() < objFromDate.getTime()) {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                text: "To date must be greater than from date",
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })
                                                                        } else {

                                                                            // setDisplaySelectedFormatBtn(null);
                                                                            field.onChange(moment(value).format('yyyy-MM-DD'));
                                                                            var strToDate = moment(value).format('yyyy-MM-DD');
                                                                            setToDate(strToDate);
                                                                            const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                                                            setSelectedCheckUserId(updatedUserIds)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                    <div className="col-12 col-md-3 mb-0">
                                        <div className="form-group cust-tagbox mb-0">
                                            {/* <label className="label-control">Branch&nbsp;</label> */}
                                            <Controller
                                                name="city_state_master_id"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: arrCompanyName,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        deferRendering={true}
                                                        displayExpr="title"
                                                        valueExpr="value"
                                                        placeholder="Select Branch"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={2}
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        acceptCustomValue={false}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        // disabled={selectedDepartmentValue != null ? false : true}
                                                        value={field.value != null && field.value.length > 0 ? field.value : selectedBranch}
                                                        onValueChanged={({ value, event }) => {
                                                            if ((value != null || value !== undefined) && value.length > 0) {
                                                                setSelectedBranch(value);
                                                                setObjCompanyName(value)
                                                                // setSelectedCompanyValue([])
                                                                // setValue('company_name', null);
                                                                field.onChange(value);
                                                                // props.actionGetCompanyList({ 'policy_type_id': value, status: "ACTIVE", type: 'specific' })

                                                            } else {
                                                                if (selectedBranch != null && selectedBranch.length > 0) {
                                                                    field.onChange("");
                                                                    setSelectedBranch([])
                                                                    setObjCompanyName([])
                                                                }
                                                            }
                                                            if (event != null) {
                                                                if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                    field.onChange("");
                                                                    setSelectedBranch([])
                                                                    setObjCompanyName([])
                                                                }
                                                            }
                                                        }}
                                                        onSelectionChanged={(e) => {
                                                            if (e.addedItems.length > 0) {
                                                                e.component.field().value = "";
                                                                e.component.close();
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 mb-0">
                                        <div className="form-group cust-tagbox mb-0">
                                            {/* <label className="label-control">Sales RM Name&nbsp;</label> */}
                                            <Controller
                                                name="assigned_rm_ids"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: arrSalesRm,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        deferRendering={true}
                                                        displayExpr="title"
                                                        valueExpr="value"
                                                        placeholder="Select Sales RM"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={2}
                                                        selectAllMode={true}
                                                        showSelectionControls={true}
                                                        acceptCustomValue={false}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        // disabled={selectedDepartmentValue != null ? false : true}
                                                        value={field.value != null && field.value.length > 0 ? field.value : selectedRmName}
                                                        onValueChanged={({ value, event }) => {
                                                            if ((value != null || value !== undefined) && value.length > 0) {
                                                                setObjSalesRM(value)
                                                                // setSelectedCompanyValue([])
                                                                // setValue('company_name', null);
                                                                field.onChange(value);
                                                                // props.actionGetCompanyList({ 'policy_type_id': value, status: "ACTIVE", type: 'specific' })

                                                            } else {
                                                                if (selectedRmName != null && selectedRmName.length > 0) {
                                                                    field.onChange("");
                                                                    setSelectedRMName([])
                                                                    setObjSalesRM([])
                                                                }
                                                            }
                                                            if (event != null) {
                                                                if ($(event.currentTarget).hasClass('dx-clear-button-area')) {
                                                                    field.onChange("");
                                                                    setSelectedRMName([])
                                                                    setObjSalesRM([])
                                                                }
                                                            }
                                                        }}
                                                        // onSelectionChanged={(e) => {
                                                        //     if (e.addedItems.length > 0) {
                                                        //         e.component.field().value = "";
                                                        //         e.component.close();
                                                        //     }
                                                        // }}
                                                        showClearButton={true}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-end mt-half">
                                    <div className="col-4 col-md-auto pl-1 pl-md-0">
                                        <div className="form-actions mt-0 border-0 float-none py-0">
                                            <button
                                                id="apply"
                                                type='submit'
                                                className="btn btn-primary mr-8  btn-sm w-80 shadow round py-half"
                                                onClick={(event) => {
                                                    $("#progress-loader").removeClass("hidden");
                                                    setIsResetClicked(false)
                                                    event.preventDefault();
                                                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                                    setSelectedCheckUserId(updatedUserIds)
                                                    props.actionProposalPoolList({
                                                        from_date: fromDate,
                                                        to_date: toDate,
                                                        type: proposalType,
                                                        user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                                        date_type: dateType,
                                                        opportunity_type: selectedPolicyValue,
                                                        city_state_master_id: objCompanyName,
                                                        assigned_rm_ids: objSalesRM,
                                                        department_ids: objDepartment,
                                                        policy_type_ids: objPolicyType,
                                                        min_expected_premium: Number(minPremium),
                                                        max_expected_premium: Number(maxPremium),
                                                        min_probability: Number(minProbability),
                                                        max_probability: Number(maxProbability),
                                                        client_type: selectedClientType,
                                                        category: selectedCategory,
                                                        industrial_segment_id: objIndustrialSegment,
                                        retention_rm_name:objRetentionRM

                                                    })
                                                }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp;Apply
                                                    <span id="apply-filter-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-auto pl-3 pl-md-0">
                                        <div className="form-actions mt-0 border-0 float-none py-0">
                                            <button
                                                id="reset"
                                                type='button'
                                                className="btn btn-primary  btn-sm w-80 shadow round py-half"
                                                onClick={(event) => {
                                                    setToDate(moment(new Date()).format('YYYY-MM-DD'))
                                                    setFromDate(moment().month() < 3
                                                        ? moment()
                                                            .month(3)
                                                            .startOf("month")
                                                            .subtract(1, "years")
                                                            .format("YYYY-MM-DD")
                                                        : moment()
                                                            .month(3)
                                                            .startOf("month")
                                                            .format("YYYY-MM-DD"));
                                                    $("#progress-loader").removeClass("hidden");
                                                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                                    setSelectedCheckUserId(updatedUserIds)
                                                    props.actionProposalPoolList({
                                                        from_date: moment(new Date()).format('YYYY-MM-DD'),
                                                        to_date: moment().month() < 3
                                                            ? moment()
                                                                .month(3)
                                                                .startOf("month")
                                                                .subtract(1, "years")
                                                                .format("YYYY-MM-DD")
                                                            : moment()
                                                                .month(3)
                                                                .startOf("month")
                                                                .format("YYYY-MM-DD"),
                                                        type: proposalType,
                                                        user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id]
                                                    })
                                                    setIsResetClicked(true)
                                                    setSelectedPolicyValue([]);
                                                    setObjSalesRM([]);
                                                    setObjRetentionRM([])
                                                    setObjCompanyName([]);
                                                    setObjDepartment([])
                                                    setObjPolicyType([])
                                                    setSelectedClientType(null)
                                                    setSelectedCategory(null)
                                                    setSelectedClientType(null)
                                                    setObjIndustrialSegment()
                                                    setValue('min_probability', null);
                                                    setValue('max_probability', null);
                                                    setValue('max_expected_premium', null);
                                                    setValue('min_expected_premium', null);
                                                    setValue('city_state_master_id', null);
                                                    setSelectedBranch([])
                                                    setValue('opportunity_type', null);
                                                    setDateType('opportunity_create_date')
                                                    setValue('date_type', 'opportunity_create_date')
                                                    setValue('category', null);
                                                    setValue('client_type', null);
                                                    setValue('policy_type_ids', null);
                                                    setSelectedPolicy([]);
                                                    setMaxProbability(null);
                                                    setMinProbability(null);
                                                    setMinPremium(null);
                                                    setMaxPremium(null);
                                                    setValue('department_ids', null);
                                                    setSelectedDepartment([])
                                                    setValue('assigned_rm_ids', null);
                                                    setSelectedRMName([])
                                                    setDisplaySelectedFormatBtn(null);
                                                    setValue('industrial_segment_id', null);


                                                }
                                                }
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp;Reset
                                                    <span id="reset-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-auto pl-3 pl-md-0">
                                        <div className="form-actions mt-0 border-0 float-none py-0">
                                            <button
                                                id="reset"
                                                type='button'
                                                className="btn btn-primary  btn-sm w-80 shadow round py-half"
                                                onClick={(event) => {
                                                    setShowOpportunityPoolFilter(true)
                                                }
                                                }
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp;Filter
                                                    <span id="reset-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {proposalType === "list_bulk_renewal" || proposalType === "list_bulk_quote_reject" ?
                <div className="row justify-content-between">
                    <div>
                        <button className="btn btn-primary btn-md px-1 mx-1 font-small-3 shadow round mb-2" onClick={handleSelectAll}>Select All</button>
                        <button className="btn btn-outline btn-md px-1 font-small-3 shadow round mb-2" onClick={() => {
                            setSelectedRows(new Set());
                        }}> Clear All </button>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-md px-1 mx-1 font-small-3 shadow round mb-2"
                            disabled={selectedRows.size === 0}
                            onClick={() => {
                                if (proposalType === "list_bulk_quote_reject") {
                                    setShowChangeStatus(true);
                                    return;
                                } else {
                                    Swal.fire({
                                        icon: 'warning',
                                        text: `Are you sure, you want to renew these transactions without any changes?`,
                                        showCancelButton: true,
                                        cancelButtonText: 'No',
                                        showConfirmButton: true,
                                        confirmButtonText: 'Yes',
                                    }).then(result => {
                                        if (result.isConfirmed) {
                                            const input = {
                                                type: "bulk_renewal",
                                                ids: Array.from(selectedRows),
                                            }
                                            handleBulkUpdate(input);
                                        }
                                    })
                                }
                            }}>
                            {proposalType === "list_bulk_renewal" ? "Process Renewal" : "Process Rejection"}
                        </button>
                    </div>
                </div> : <></>}

            {/* DATAGRID */}

            <div className="row" >
                <div className="col-12 col-md-12">
                    <div className="card shadow ">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className='row'>
                                        <div className="col-12 col-md-auto">
                                            <div class="form-group">
                                                <div class="btn-group btn-group-sm" role="group">
                                                    <button type="button"
                                                        className={`btn ${displaySelectedFormatBtn == 'Day' ? 'btn-primary' : 'btn-outline-primary'} border-right-0`}
                                                        onClick={() => {
                                                            $("#progress-loader").removeClass("hidden");
                                                            setDisplaySelectedFormatBtn('Day');
                                                            setFromDate(day.start);
                                                            setToDate(day.end);
                                                            const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                                            setSelectedCheckUserId(updatedUserIds)
                                                            props.actionProposalPoolList({
                                                                type: proposalType,
                                                                from_date: day.start,
                                                                to_date: day.end,
                                                                user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                                                // other Filters
                                                                date_type: dateType,
                                                                opportunity_type: selectedPolicyValue,
                                                                city_state_master_id: objCompanyName,
                                                                assigned_rm_ids: objSalesRM,
                                                                department_ids: objDepartment,
                                                                policy_type_ids: objPolicyType,
                                                                min_expected_premium: minPremium,
                                                                max_expected_premium: maxPremium,
                                                                min_probability: minProbability,
                                                                max_probability: maxProbability,
                                                                client_type: selectedClientType,
                                                                category: selectedCategory,
                                                                industrial_segment_id: objIndustrialSegment,
                                                                retention_rm_name:objRetentionRM

                                                            })
                                                        }}
                                                    >DAY</button>
                                                    <button type="button"
                                                        className={`btn ${displaySelectedFormatBtn == 'Week' ? 'btn-primary' : 'btn-outline-primary'}`}
                                                        onClick={() => {
                                                            $("#progress-loader").removeClass("hidden");
                                                            let week = Object.assign({}, getDateFilter('WEEK'))
                                                            setDisplaySelectedFormatBtn('Week');
                                                            setFromDate(week.start);
                                                            setToDate(week.end);
                                                            const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                                            setSelectedCheckUserId(updatedUserIds)
                                                            props.actionProposalPoolList({
                                                                type: proposalType,
                                                                from_date: week.start,
                                                                to_date: week.end,
                                                                user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                                                //  Other Filters
                                                                date_type: dateType,
                                                                opportunity_type: selectedPolicyValue,
                                                                city_state_master_id: objCompanyName,
                                                                assigned_rm_ids: objSalesRM,
                                                                department_ids: objDepartment,
                                                                policy_type_ids: objPolicyType,
                                                                min_expected_premium: Number(minPremium),
                                                                max_expected_premium: Number(maxPremium),
                                                                min_probability: Number(minProbability),
                                                                max_probability: Number(maxProbability),
                                                                client_type: selectedClientType,
                                                                category: selectedCategory,
                                                                industrial_segment_id: objIndustrialSegment,
                                                                retention_rm_name:objRetentionRM
                                                                
                                                            })
                                                        }}
                                                    >WEEK</button>
                                                    <button type="button"
                                                        className={`btn ${displaySelectedFormatBtn == 'MONTH' ? 'btn-primary' : 'btn-outline-primary'} border-right-0`}
                                                        onClick={() => {
                                                            $("#progress-loader").removeClass("hidden");
                                                            let month = Object.assign({}, getDateFilter('MONTH'))
                                                            setFromDate(month.start);
                                                            setToDate(month.end);
                                                            setDisplaySelectedFormatBtn('MONTH');

                                                            const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                                            setSelectedCheckUserId(updatedUserIds)
                                                            props.actionProposalPoolList({
                                                                type: proposalType,
                                                                from_date: month.start,
                                                                to_date: month.end,
                                                                user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                                                //   Other Filters
                                                                date_type: dateType,
                                                                opportunity_type: selectedPolicyValue,
                                                                city_state_master_id: objCompanyName,
                                                                assigned_rm_ids: objSalesRM,
                                                                department_ids: objDepartment,
                                                                policy_type_ids: objPolicyType,
                                                                min_expected_premium: Number(minPremium),
                                                                max_expected_premium: Number(maxPremium),
                                                                min_probability: Number(minProbability),
                                                                max_probability: Number(maxProbability),
                                                                client_type: selectedClientType,
                                                                category: selectedCategory,
                                                                industrial_segment_id: objIndustrialSegment,
                                                                retention_rm_name:objRetentionRM

                                                            })
                                                        }}
                                                    >MONTH</button>
                                                    <button type="button"
                                                        className={`btn ${displaySelectedFormatBtn == 'YEAR' ? 'btn-primary' : 'btn-outline-primary'}`}
                                                        onClick={() => {
                                                            $("#progress-loader").removeClass("hidden");
                                                            let year = Object.assign({}, getDateFilter('YEAR'))
                                                            setFromDate(year.start);
                                                            setToDate(year.end);
                                                            setDisplaySelectedFormatBtn('YEAR');
                                                            const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                                                            setSelectedCheckUserId(updatedUserIds)
                                                            props.actionProposalPoolList({
                                                                type: proposalType,
                                                                from_date: year.start,
                                                                to_date: year.end,
                                                                user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                                                                // Other Filters
                                                                date_type: dateType,
                                                                opportunity_type: selectedPolicyValue,
                                                                city_state_master_id: objCompanyName,
                                                                assigned_rm_ids: objSalesRM,
                                                                department_ids: objDepartment,
                                                                policy_type_ids: objPolicyType,
                                                                min_expected_premium: Number(minPremium),
                                                                max_expected_premium: Number(maxPremium),
                                                                min_probability: Number(minProbability),
                                                                max_probability: Number(maxProbability),
                                                                client_type: selectedClientType,
                                                                category: selectedCategory,
                                                                industrial_segment_id: objIndustrialSegment,
                                                                retention_rm_name:objRetentionRM

                                                            })
                                                        }}
                                                    >YEAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-1 justify-content-end d-flex">
                                    {
                                        myTeamMembers != null && myTeamMembers.length > 0 ?
                                            <div className="my-auto">
                                                <div className="form-group mb-0 mr-1">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            name="with_team"
                                                            id="with_team"
                                                            defaultChecked={isWithTeam === 1 ? true : false}
                                                            checked={isWithTeam === 1 ? true : false}
                                                            {...register('with_team')}
                                                            onChange={(event) => {
                                                                if (event.target.checked === true) {
                                                                    setIsWithTeam(1)
                                                                } else {
                                                                    setIsWithTeam(0)
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-control-label" for="with_team">With Team Data</label>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }

                                    <div className="my-auto">
                                        <div className="form-group mb-0 mr-1">
                                            <div class="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    name="my_data"
                                                    id="my_data"
                                                    defaultChecked={isMyData === 1 ? true : false}
                                                    checked={isMyData === 1 ? true : false}
                                                    {...register('my_data')}
                                                    onChange={(event) => {
                                                        if (event.target.checked === true) {
                                                            setMyData(1)
                                                        } else {
                                                            setMyData(0)
                                                        }
                                                    }}
                                                />
                                                <label class="custom-control-label" for="my_data">My Data</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <div className="row"> */}
                            <DataGrid
                                dataSource={proposalPoolList}
                                // remoteOperations={true}
                                showRowLines={true}
                                showColumnLines={true}
                                showBorders={true}
                                export={{
                                    fileName: "Opportunity Pool",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                    enabled: verifyPermission("OPPORTUNITY_POOL_EXCEL_EXPORT") ? true : false
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("id", "visible", true);
                                    event.component.columnOption("created_at", "visible", true); // Created Date comes after ID
                                    event.component.columnOption("segment_name", "visible", proposalType != 'proposal_success' ? true : false)
                                    event.component.columnOption("category", "visible", true)
                                    event.component.columnOption("client_created_at", "visible", true)
                                    event.component.columnOption("updated_at", "visible", true)
                                    event.component.columnOption("formated_expiring_date", "visible", false)
                                    event.component.columnOption("expiring_date", "visible", true)
                                    event.component.columnOption("formated_created_at", "visible", false)
                                    event.component.columnOption("created_at", "visible", true)
                                    event.component.columnOption("retention_rm_name", "visible", true)
                                    

                                    event.component.columnOption("client_type", "visible", proposalType === 'quote_rejected' || proposalType === 'proposal_success' || proposalType === 'proposal_lost' ? true : false)
                                    event.component.columnOption("company_group_name", "visible", proposalType === 'quote_rejected' || proposalType === 'proposal_success' || proposalType === 'proposal_lost' ? true : false)
                                    event.component.columnOption("opportunity_sub_status", "visible", proposalType === 'quote_rejected' || proposalType === 'proposal_lost' ? true : false)
                                    event.component.columnOption("opportunity_remark", "visible", proposalType === 'quote_rejected' ? true : false)
                                    event.component.columnOption("updated_at", "visible", proposalType === 'quote_rejected' || proposalType === 'proposal_success' || proposalType === 'proposal_lost' ? true : false)
                                    // event.component.columnOption("last_meeting_date", "visible", proposalType === 'quote_rejected' || proposalType === 'proposal_success' || proposalType === 'proposal_lost' ? true : false)

                                    // event.component.columnOption("rfp_insure_count", "visible", proposalType === 'proposal_lost' || proposalType === 'proposal_success' ? true : false)
                                    event.component.columnOption("lost_intermediary_type", "visible", proposalType === 'proposal_lost' ? true : false)
                                    event.component.columnOption("lost_intermediary_name", "visible", proposalType === 'proposal_lost' ? true : false)
                                    event.component.columnOption("lost_insurer_name", "visible", proposalType === 'proposal_lost' ? true : false)
                                    event.component.columnOption("lost_net_amount", "visible", proposalType === 'proposal_lost' ? true : false)

                                    event.component.columnOption("closure_type", "visible", proposalType === 'proposal_success' ? true : false)
                                    event.component.columnOption("success_premium_amount", "visible", proposalType === 'proposal_success' ? true : false)
                                    // event.component.columnOption("success_pinc_share", "visible", proposalType === 'proposal_success' ? true : false)
                                    // event.component.columnOption("total_share_premium", "visible", proposalType === 'proposal_success' ? true : false)
                                    event.component.columnOption("success_insurer_name", "visible", proposalType === 'proposal_success' ? true : false)
                                    event.component.columnOption("success_instalment", "visible", proposalType === 'proposal_success' ? true : false)
                                    event.component.columnOption("success_policy_start_date", "visible", proposalType === 'proposal_success' ? true : false)
                                    event.component.columnOption("formated_end_date", "visible", proposalType === 'proposal_success' ? true : false)

                                }}
                                onExported={(event) => {
                                    event.component.columnOption("segment_name", "visible", false)
                                    event.component.columnOption("category", "visible", false)
                                    event.component.columnOption("client_created_at", "visible", false)
                                    event.component.columnOption("updated_at", "visible", false)
                                    event.component.columnOption("formated_expiring_date", "visible", true)
                                    event.component.columnOption("expiring_date", "visible", false)
                                    event.component.columnOption("formated_created_at", "visible", true)
                                    event.component.columnOption("created_at", "visible", false)
                                    event.component.columnOption("retention_rm_name", "visible", true)

                                    event.component.columnOption("client_type", "visible", false)
                                    event.component.columnOption("company_group_name", "visible", false)
                                    event.component.columnOption("opportunity_sub_status", "visible", false)
                                    event.component.columnOption("opportunity_remark", "visible", false)
                                    event.component.columnOption("updated_at", "visible", false)
                                    // event.component.columnOption("last_meeting_date", "visible", false)

                                    // event.component.columnOption("rfp_insure_count", "visible", false)
                                    event.component.columnOption("lost_intermediary_type", "visible", false)
                                    event.component.columnOption("lost_intermediary_name", "visible", false)
                                    event.component.columnOption("lost_insurer_name", "visible", false)
                                    event.component.columnOption("lost_net_amount", "visible", false)

                                    event.component.columnOption("closure_type", "visible", false)
                                    event.component.columnOption("success_premium_amount", "visible", false)
                                    // event.component.columnOption("success_pinc_share", "visible", false)
                                    // event.component.columnOption("total_share_premium", "visible", false)
                                    event.component.columnOption("success_insurer_name", "visible", false)
                                    event.component.columnOption("success_instalment", "visible", false)
                                    event.component.columnOption("success_policy_start_date", "visible", false)
                                    event.component.columnOption("formated_end_date", "visible", false)
                                    event.component.endUpdate();
                                }}
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: 'before',

                                    })
                                }}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                columnAutoWidth={true}
                            >
                                {renderColumns()}
                                <SearchPanel visible={true} />
                                <FilterRow visible={true} />
                                {/* <FilterPanel visible={true} /> */}
                                <HeaderFilter visible={true} />
                                <Grouping autoExpandAll={true} />
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[10, 20, 40]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                />
                                <Grouping autoExpandAll={false} />
                                <Summary>

                                </Summary>

                            </DataGrid>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <PolicyDetailModal
                show={showPolicyDetails}
                opportunityPool={opportunityPool}
                handleClose={(newPolicy,files) => {
                    if (newPolicy != null) {
                        var obj = {
                            id: newPolicy.id,
                            client_id: newPolicy.client_id,
                            department_id: newPolicy.department_id,
                            insurer_id: newPolicy.insurer_id,
                            brokerage_amnt: newPolicy.brokerage_amnt,
                            broker: newPolicy.broker,
                            product_specialist_id: newPolicy.product_specialist_id,
                            expected_premium: newPolicy.expected_premium,
                            // expiring_date: newPolicy.oppurtunity_type != 'Fresh' ? newPolicy.expiring_date : '',
                            expiring_date: newPolicy.expiring_date,
                            probability: newPolicy.probability,
                            policy_type_id: newPolicy.policy_type_id,
                            client_remark: newPolicy.client_remark,
                            is_product_specialist_required: newPolicy.is_product_specialist_required,
                            opportunity_questions: newPolicy.opportunity_questions,
                            oppurtunity_type: newPolicy.oppurtunity_type,
                            intermediary_type: newPolicy.intermediary_type,
                            intermediary_value: newPolicy.intermediary_value,
                            tag_user_id: newPolicy.tag_user_id,
                           posted_from: "pincnetwork"
                        }
                        props.actionAddPolicy(cleanObject(obj),files)
                    }
                    setShowPolicyDetails(false)
                }}
                data={null}
            />
            <OpportunityPoolDetails
                show={showOpportunityPoolDetails}
                details={opportunityPoolDetails}
                proposalType={proposalType}
                handleClose={() => {
                    setShowOpportunityPoolDetails(false)
                }}
            />
            <OpportunityFilter
                show={showOpportunityPoolFilter}
                details={proposalPoolList}
                fromDate={fromDate}
                toDate={toDate}
                proposalType={proposalType}
                dateType={dateType}
                myTeamMembers={myTeamMembers}
                isMyData={isMyData}
                LoginId={props.auth.getUser().user_id}
                isWithTeam={isWithTeam}
                isResetClicked={isResetClicked}
                opportunityTypeValue={opportunityTypeValue}
                departmentIdValue={departmentIdValue}
                policyTypeValue={policyTypeValue}
                expectedMinValue={expectedMinValue}
                expectedMaxValue={expectedMaxValue}
                minProbabilityValue={minProbabilityValue}
                maxProbabilityValue={maxProbabilityValue}
                clientTypeValue={clientTypeValue}
                categoryValue={categoryValue}
                industrialValue={industrialValue}
                retention_rm_name={objRetentionRM}
                handleClose={(newPolicy) => {
                    const updatedUserIds = [...myTeamMembers, props.auth.getUser().user_id];
                    setSelectedCheckUserId(updatedUserIds)
                    if (newPolicy != null) {
                        props.actionProposalPoolList({
                            from_date: fromDate,
                            to_date: toDate,
                            type: proposalType,
                            user_ids: isWithTeam && isMyData ? updatedUserIds : isMyData ? [props.auth.getUser().user_id] : isWithTeam ? myTeamMembers : [props.auth.getUser().user_id],
                            date_type: dateType,
                            opportunity_type: newPolicy?.opportunity_type,
                            city_state_master_id: objCompanyName,
                            assigned_rm_ids: objSalesRM,
                            department_ids: newPolicy.department_ids,
                            policy_type_ids: newPolicy.policy_type_ids,
                            min_expected_premium: Number(newPolicy.min_expected_premium),
                            max_expected_premium: newPolicy.max_expected_premium,
                            min_probability: newPolicy.min_probability,
                            max_probability: newPolicy.max_probability,
                            client_type: newPolicy.client_type,
                            category: newPolicy.category,
                            industrial_segment_id: newPolicy.industrial_segment_id,
                            retention_user_id:objRetentionRM
                        })
                        setOpportunityTypeValue(newPolicy.opportunity_type)
                        setDepartmentIdValue(newPolicy.department_ids)
                        setPolicyTypeValue(newPolicy.policy_type_ids)
                        setExpectedMinValue(newPolicy.min_expected_premium)
                        setExpectedMaxValue(newPolicy.max_expected_premium)
                        setMinProbabilityValue(newPolicy.min_probability)
                        setMaxProbabilityValue(newPolicy.max_probability)
                        setClientTypeValue(newPolicy.client_type)
                        setCategoryValue(newPolicy.category)
                        setIndustrialValue(newPolicy.industrial_segment_id)
                    } else {
                        setShowOpportunityPoolFilter(false)
                    }
                    setShowOpportunityPoolFilter(false)
                    setIsResetClicked(false)
                }}
            />
            <PoolBulkAction
                show={showChangeStatus}
                handleClose={() => {
                    setShowChangeStatus(false)
                }}
                handleSubmit={(data) => {
                    const input = {
                        type: "bulk_quote_reject",
                        opportunity_sub_status: data.opportunity_sub_status,
                        ids: Array.from(selectedRows),
                    }
                    handleBulkUpdate(input);
                }}
            />
        </>
    )

}
const mapStateToProps = (state) => {
    var ProposalPool = null;
    var ListTeamVertical = null;
    var AddPolicy = null;
    var DateFilter = null;
    var PolicyBulkUpdate = null;

    if (
        state.ProposalPoolListReducer != null &&
        Object.keys(state.ProposalPoolListReducer).length > 0 &&
        state.ProposalPoolListReducer.constructor === Object
    ) {
        switch (state.ProposalPoolListReducer.tag) {
            case ACTION_PROPOSALPOOL_LIST_SUCCESS:
                ProposalPool = Object.assign({}, state.ProposalPoolListReducer.data);
                delete state.ProposalPoolListReducer;
                return { ProposalPool };
            case ACTION_LIST_TEAM_VERTICAL_SUCCESS:
                ListTeamVertical = Object.assign({}, state.ProposalPoolListReducer.data);
                delete state.ProposalPoolListReducer;
                return { ListTeamVertical };
            case ACTION_ADD_POLICY_SUCCESS:
                AddPolicy = Object.assign({}, state.ProposalPoolListReducer.data);
                delete state.ProposalPoolListReducer;
                return { AddPolicy };
            case ACTION_DATE_TYPE_SUCCESS:
                DateFilter = Object.assign({}, state.ProposalPoolListReducer.data);
                delete state.ProposalPoolListReducer;
                return { DateFilter };
            case ACTION_OPPORTUNITY_BULK_UPDATE_SUCCESS:
                PolicyBulkUpdate = Object.assign({}, state.ProposalPoolListReducer.data);
                delete state.ProposalPoolListReducer;
                return { PolicyBulkUpdate };
            default:
        }
    }
    return {
        ProposalPool,
        ListTeamVertical,
        AddPolicy,
        DateFilter,
        PolicyBulkUpdate
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionProposalPoolList, actionTeamList, actionAddPolicy, actionDateType, actionProposalBulkUpdate }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ProposalPoolList))