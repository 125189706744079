import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { withAuth } from 'react-auth-guard';
import { FiEdit, FiEye, FiLock } from 'react-icons/fi';
import { ERROR, PRIMARY_COLOR, UNAUTHORIZED } from '../../../../../../constants';
import Swal from 'sweetalert2';
import $ from "jquery";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import _ from "lodash";
import { Break } from 'devextreme-react/chart';
import { ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS, ACTION_EMPLOYEE_GET_REPORTING_SUCCESS, ACTION_EMPLOYEE_LIST_SUCCESS, ACTION_EMPLOYEE_STATUS_SUCCESS, ACTION_MASTER_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS, ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS, actionEmployeeChangeUserStatus, actionEmployeeGetReporting, actionEmployeeList, actionEmployeeListActive, actionEmployeeStatus, actionReimbursementFinanceApprovalManager, } from './action';
import ErrorLabel from '../../../../../../component/form/error_label';
import { Controller, useForm } from 'react-hook-form';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';

const EmployeeInactiveUpdateModal = (props) => {
  const { handleClose, inactiveEmployeeId, show } = props;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors }
  } = useForm();

  const [getReportingManager, setReportingManager] = useState([]);
  const [activeEmployeeCount, setActiveEmployeeCount] = useState(0);
  const [employee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [masterfinanceReimbursementApprovalManager, setMasterfinanceReimbursementApprovalManager] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");



  const approvalFields = [
    "approval_manager",
    "asset_approval_manager",
    "finance_approval_manager",
    "travel_approval_manager",
    "attendance_approval_manager",
    "meeting_approval_manager",
    "performance_approval_manager",
    "reimbursement_approval_manager",
  ];

  const initialRender = () => {
    if (props.show === true && inactiveEmployeeId?.user_id) {
      props.actionEmployeeGetReporting({ user_id: inactiveEmployeeId.user_id }); // Send user_id in body
      props.actionReimbursementFinanceApprovalManager();

      $("#root").addClass("modal-open");
    } else {
      setReportingManager([]);
      $("#root").removeClass("modal-open");
    }
  };


  useEffect(initialRender, [props.show, inactiveEmployeeId]);



  const onReportingManagerUpdate = () => {
    const { ReportingManager } = props;
    var ReportingManagerResponse = Object.assign({}, ReportingManager);

    if (ReportingManagerResponse.result === true) {
      // Check if response is null
      if (!ReportingManagerResponse.response) {
        // Swal.fire({
        //   title: "No Data Found",
        //   text: "Reporting Manager data is null.",
        //   icon: "warning",
        //   confirmButtonText: "OK"
        // });
        return; // Exit function to avoid errors
      }

      // Store data in state
      setReportingManager({
        reporting_to: ReportingManagerResponse.response.reporting_to_id || [],
        approval_manager: ReportingManagerResponse.response.approval_manager_id || [],
        asset_manager: ReportingManagerResponse.response.asset_manager_id || [],
        finance_manager: ReportingManagerResponse.response.finance_manager_id || [],
        travel_manager: ReportingManagerResponse.response.travel_manager_id || [],
        attendance_manager: ReportingManagerResponse.response.attendance_manager_id || [],
        meeting_manager: ReportingManagerResponse.response.meeting_manager_id || [],
        performance_manager: ReportingManagerResponse.response.performance_manager_id || [],
        reimburstment_approval_manager: ReportingManagerResponse.response.reimburstment_approval_manager_id || [],
        finance_reimburstment_approval_manager: ReportingManagerResponse.response.finance_reimbursement_approval_manager || [],
      });

    } else {
      switch (ReportingManagerResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success("Your Login session has expired, Please try to Login again!");
          break;
        case 402: // If API returns status 402, show a warning popup
          Swal.fire({
            title: "No Data Available",
            text: "Employee Details is null.",
            icon: "warning",
            confirmButtonText: "OK"
          });
          break;
        default:
      }
    }
  };


  useEffect(onReportingManagerUpdate, [props.ReportingManager]);



  const onListReimbursementFinanceApprovalManagerUpdate = () => {
    const { ListReimbursementFinanceApprovalManager } = props;

    var ListReimbursementFinanceApprovalManagerResponse = Object.assign({}, ListReimbursementFinanceApprovalManager);
    if (ListReimbursementFinanceApprovalManagerResponse.result === true) {
      setMasterfinanceReimbursementApprovalManager(ListReimbursementFinanceApprovalManagerResponse.response)
    } else {
      switch (ListReimbursementFinanceApprovalManagerResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success("Your Login session has expired, Please try to Login again!");
          break;
        default:
      }
    }
  }

  useEffect(onListReimbursementFinanceApprovalManagerUpdate, [props.ListReimbursementFinanceApprovalManager])




  const onEmployeeListUpdate = () => {
    const { EmployeeList } = props;
    var EmployeeResponse = Object.assign({}, EmployeeList);
    if (EmployeeResponse.result === true) {
      setActiveEmployeeCount(0);
      setEmployee(EmployeeResponse.response);
      $("#progress-loader").addClass("hidden");
    } else {
      switch (EmployeeResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success("Your Login session has expired, Please try to Login again!");
          break;
        default:
      }
    }
  }
  useEffect(onEmployeeListUpdate, [props.EmployeeList])






  const onEmployeeActiveListUpdate = () => {
    const { EmployeeActiveList } = props;
    var EmployeeResponse = Object.assign({}, EmployeeActiveList);
    if (EmployeeResponse.result === true) {
      setActiveEmployeeCount(EmployeeResponse.response.length);
      setEmployee(EmployeeResponse.response);
      $("#progress-loader").addClass("hidden");
    } else {
      switch (EmployeeResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success("Your Login session has expired, Please try to Login again!");
          break;
        default:
      }
    }
  }
  useEffect(onEmployeeActiveListUpdate, [props.EmployeeActiveList])

  // Watch reporting_to changes
  const reportingTo = watch("reporting_to");

  // Update fields dynamically when reporting_to changes
  useEffect(() => {
    if (!reportingTo) return;

    approvalFields.forEach((field) => {
      const currentValue = getValues(field);
      if (!currentValue || currentValue === selectedUserId) {
        setValue(field, reportingTo);
      }
    });

    setSelectedUserId(reportingTo);
  }, [reportingTo, setValue, getValues]);


  const onEmployeeStatusUpdate = () => {
    const { EmployeeStatus } = props;
    if (EmployeeStatus != null) {
      var EmployeeStatusResponse = Object.assign({}, EmployeeStatus);
      $("#progress-loader").addClass("hidden");
  
      if (EmployeeStatusResponse.result === true) {
        setData(KEY_TOAST_MESSAGE, "User Status Change");
        // props.history.goBack();
        props.handleClose();
      } else {
        switch (EmployeeStatusResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          default:
            toast.error("Failed to update user status.");
        }
      }
    }
  };
  useEffect(onEmployeeStatusUpdate, [props.EmployeeStatus]);
  
  const onSubmit = async (data) => {
    console.log("Form Submitted, Data:", data); // Debugging
  
    setLoading(true); // Show loader during submission
    const userId = inactiveEmployeeId?.user_id || null;
    const user_status =
      inactiveEmployeeId?.status !== undefined
        ? Boolean(inactiveEmployeeId.status)
        : null;
  
    const payload = {
      id: userId,
      status: user_status,
      reporting_to_id: Number(data?.reporting_to) || null,
      approval_manager_id: Number(data?.approval_manager) || null,
      asset_manager_id: Number(data?.asset_approval_manager) || null,
      finance_manager_id: Number(data?.finance_approval_manager) || null,
      travel_manager_id: Number(data?.travel_approval_manager) || null,
      attendance_manager_id: Number(data?.attendance_approval_manager) || null,
      meeting_manager_id: Number(data?.meeting_approval_manager) || null,
      performance_manager_id: Number(data?.performance_approval_manager) || null,
      reimburstment_approval_manager_id: Number(data?.reimbursement_approval_manager) || null,
      finance_reimburstment_approval_manager_id:
        user_status === false
          ? Number(data?.finance_reimburstment_approval_manager_id) || 0
          : null,
    };
  
    console.log("Submitting Payload:", payload); // Debugging
  
    try {
      if (props.actionEmployeeStatus) {
        await props.actionEmployeeStatus(payload); // Ensure it's called correctly
        console.log("API Call Successful"); // Debugging
        toast.success("User status change request submitted!"); // Show success message
      } else {
        console.error("actionEmployeeStatus function is missing!");
        toast.error("Action handler is missing.");
      }
    } catch (error) {
      console.error("API Call Failed:", error); // Debugging
      toast.error("Something went wrong! Please try again.");
    }
  
    setLoading(false); // Hide loader
    handleClose(); // Close modal
  };





  return (
    <>
      {props.show && <div className="modal-backdrop fade show"></div>}
      <div
        className="modal fade show"
        id="default"
        role="dialog"
        aria-labelledby="employee-update-inactive-name"
        aria-hidden="true"
        style={{ display: props.show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Replace Inactive Employee</h3>
              <button type="button" className="close" onClick={handleClose}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                {/* Employee Details Header */}
                <h4 className="fw-bold px-2 border-bottom pb-2">Employee Details</h4>

                {/* Employee Info Section */}
                <div className="row mt-3">
                  <div className="col-md-6">
                    <p className="fw-semibold px-3">
                      <strong>Employee ID:</strong> {inactiveEmployeeId?.pinc_employee_id}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="fw-semibold">
                      <strong>Name:</strong> {inactiveEmployeeId?.full_name}
                    </p>
                  </div>
                </div>

                {/* Layout: Left = Current Managers, Right = Assign New Managers */}
                <div className="row mt-4">
                  {/* Left Column: Current Reporting Managers */}
                  <div className="col-md-6 px-3">
                    <h5 className="fw-bold mb-3">Current Reporting Managers</h5>

                    {/* Reporting To */}
                    <div className="mb-3">
                      <label className="fw-semibold">Reporting To:</label>

                      <DropDownListComponent
                        dataSource={getReportingManager.reporting_to} // Use the correct data source
                        fields={{ text: "full_name", value: "user_id" }}
                        placeholder={getReportingManager.reporting_to?.length ? "Click to view Reportees" : "Not Assigned"}
                        enabled={getReportingManager.reporting_to?.length > 0}
                        className="form-control"
                        openOnClick={true}
                        allowFiltering={false}
                        select={(e) => e.cancel = true}
                      />
                    </div>

                    {/* Approval Manager */}
                    <div className="mb-3">
                      <label className="fw-semibold">Approval Manager</label>
                      <DropDownListComponent
                        dataSource={getReportingManager.approval_manager}
                        fields={{ text: "full_name", value: "user_id" }}
                        placeholder={getReportingManager.approval_manager?.length ? "Click to view Reportees" : "Not Assigned"}
                        enabled={getReportingManager.approval_manager?.length > 0}
                        className="form-control"
                        openOnClick={true}
                        allowFiltering={false}
                        select={(e) => e.cancel = true}
                      />
                    </div>

                    {/* Asset Approval Manager */}
                    <div className="mb-3">
                      <label className="fw-semibold">Asset Approval Manager</label>
                      <DropDownListComponent
                        dataSource={getReportingManager.asset_manager}
                        fields={{ text: "full_name", value: "user_id" }}
                        placeholder={getReportingManager.asset_manager?.length ? "Click to view Reportees" : "Not Assigned"}
                        enabled={getReportingManager.asset_manager?.length > 0}
                        className="form-control"
                        openOnClick={true}
                        allowFiltering={false}
                        select={(e) => e.cancel = true}
                      />
                    </div>

                    {/* Finance Approval Manager */}
                    <div className="mb-3">
                      <label className="fw-semibold">Finance Approval Manager</label>
                      <DropDownListComponent
                        dataSource={getReportingManager.finance_manager}
                        fields={{ text: "full_name", value: "user_id" }}
                        placeholder={getReportingManager.finance_manager?.length ? "Click to view Reportees" : "Not Assigned"}
                        enabled={getReportingManager.finance_manager?.length > 0}
                        className="form-control"
                        openOnClick={true}
                        allowFiltering={false}
                        select={(e) => e.cancel = true}
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                      />
                    </div>

                    {/* Travel Approval Manager */}
                    <div className="mb-3">
                      <label className="fw-semibold">Travel Approval Manager</label>
                      <DropDownListComponent
                        dataSource={getReportingManager.travel_manager}
                        fields={{ text: "full_name", value: "user_id" }}
                        placeholder={getReportingManager.travel_manager?.length ? "Click to view Reportees" : "Not Assigned"}
                        enabled={getReportingManager.travel_manager?.length > 0}
                        className="form-control"
                        openOnClick={true}
                        allowFiltering={false}
                        select={(e) => e.cancel = true}
                      />
                    </div>

                    {/* Attendance Approval Manager */}
                    <div className="mb-3">
                      <label className="fw-semibold">Attendance Approval Manager</label>
                      <DropDownListComponent
                        dataSource={getReportingManager.attendance_manager}
                        fields={{ text: "full_name", value: "user_id" }}
                        placeholder={getReportingManager.attendance_manager?.length ? "Click to view Reportees" : "Not Assigned"}
                        enabled={getReportingManager.attendance_manager?.length > 0}
                        className="form-control"
                        openOnClick={true}
                        allowFiltering={false}
                        select={(e) => e.cancel = true}
                      />
                    </div>

                    {/* Meeting Approval Manager */}
                    <div className="mb-3">
                      <label className="fw-semibold">Meeting Approval Manager</label>
                      <DropDownListComponent
                        dataSource={getReportingManager.meeting_manager}
                        fields={{ text: "full_name", value: "user_id" }}
                        placeholder={getReportingManager.meeting_manager?.length ? "Click to view Reportees" : "Not Assigned"}
                        enabled={getReportingManager.meeting_manager?.length > 0}
                        className="form-control"
                        openOnClick={true}
                        allowFiltering={false}
                        select={(e) => e.cancel = true}
                      />
                    </div>

                    {/* Performance Approval Manager */}
                    <div className="mb-3">
                      <label className="fw-semibold">Performance Approval Manager</label>
                      <DropDownListComponent
                        dataSource={getReportingManager.performance_manager}
                        fields={{ text: "full_name", value: "user_id" }}
                        placeholder={getReportingManager.performance_manager?.length ? "Click to view Reportees" : "Not Assigned"}
                        enabled={getReportingManager.performance_manager?.length > 0}
                        className="form-control"
                        openOnClick={true}
                        allowFiltering={false}
                        select={(e) => e.cancel = true}
                      />
                    </div>

                    {/* Reimbursement Approval Manager */}
                    <div className="mb-3">
                      <label className="fw-semibold">Reimbursement Approval Manager</label>
                      <DropDownListComponent
                        dataSource={getReportingManager.reimburstment_approval_manager}
                        fields={{ text: "full_name", value: "user_id" }}
                        placeholder={getReportingManager.reimburstment_approval_manager?.length ? "Click to view Reportees" : "Not Assigned"}
                        enabled={getReportingManager.reimburstment_approval_manager?.length > 0}
                        className="form-control"
                        openOnClick={true}
                        allowFiltering={false}
                        select={(e) => e.cancel = true}
                      />
                    </div>

                    {/* Finance Approval Manager */}
                    <div className="mb-3">
                      <label className="fw-semibold">Finance Reimbursement Approval Manager</label>
                      <DropDownListComponent
                        dataSource={getReportingManager.finance_reimbursement_approval_manager}
                        fields={{ text: "full_name", value: "user_id" }}
                        placeholder={getReportingManager.finance_reimbursement_approval_manager?.length ? "Click To View Finance Reimbursement Approval Manager" : "Not Assigned"}
                        enabled={getReportingManager.finance_reimbursement_approval_manager?.length > 0}
                        className="form-control"
                        openOnClick={true}
                        allowFiltering={false}
                        select={(e) => e.cancel = true}
                      />
                    </div>
                  </div>

                  {/* Right Column: Assign New Managers */}
                  <div className="col-md-6 px-3">
                    <h5 className="fw-bold mb-3">Assign New Reporting Managers</h5>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      {/* Reporting To */}
                      <div className="mb-3">
                        <label className="fw-semibold">Reporting To</label>
                        <DropDownListComponent
                          dataSource={employee}
                          fields={{ text: "full_name", value: "user_id" }}
                          placeholder={employee.length ? "Select Reporting Manager" : "Not Assigned"}
                          enabled={employee.length > 0}
                          {...register("reporting_to", { required: "Reporting Manager is required" })}
                          className="form-control"
                        />
                        {errors.reporting_to && <span className="text-danger">{errors.reporting_to.message}</span>}
                      </div>

                      {/* Approval Managers */}
                      {approvalFields.map((field) => (
                        <div className="mb-3" key={field}>
                          <label className="fw-semibold">
                            {field.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())}
                          </label>
                          <DropDownListComponent
                            dataSource={employee}
                            fields={{ text: "full_name", value: "user_id" }}
                            placeholder={employee.length ? `Select ${field.replace(/_/g, " ")}` : "Not Assigned"}
                            enabled={employee.length > 0}
                            {...register(field, { required: `${field.replace(/_/g, " ")} is required` })}
                            className="form-control"
                          />
                          {errors[field] && <span className="text-danger">{errors[field].message}</span>}
                        </div>
                      ))}

                      {/* Finance Reimbursement Approval Manager */}
                      <div className="mb-3">
                        <label className="fw-semibold">Finance Reimbursement Approval Manager</label>
                        <DropDownListComponent
                          dataSource={masterfinanceReimbursementApprovalManager}
                          fields={{ text: "full_name", value: "user_id" }}
                          placeholder={masterfinanceReimbursementApprovalManager.length ? "Select Finance Reimbursement" : "Not Assigned"}
                          enabled={masterfinanceReimbursementApprovalManager.length > 0}
                          {...register("finance_reimburstment_approval_manager", { required: "Finance Approval Manager is required" })}
                          className="form-control"
                        />
                        {errors.finance_reimburstment_approval_manager && (
                          <span className="text-danger">{errors.finance_reimburstment_approval_manager.message}</span>
                        )}
                      </div>

                      {/* Submit Button */}
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </form>

                  </div>
                </div>
              </div>
            </div>


            {/* <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );

};

const mapStateToProps = (state) => {
  var ReportingManager = null;
  var EmployeeList = null;
  var EmployeeActiveList = null;
  var EmployeeStatus = null;
  var ListReimbursementFinanceApprovalManager = null;





  if (
    state.EmployeeInactiveUpdateReducer != null &&
    Object.keys(state.EmployeeInactiveUpdateReducer).length > 0 &&
    state.EmployeeInactiveUpdateReducer.constructor === Object
  ) {
    switch (state.EmployeeInactiveUpdateReducer.tag) {
      case ACTION_EMPLOYEE_GET_REPORTING_SUCCESS:
        ReportingManager = Object.assign({}, state.EmployeeInactiveUpdateReducer.data);
        delete state.EmployeeInactiveUpdateReducer;
        return { ReportingManager };
      case ACTION_EMPLOYEE_LIST_SUCCESS:
        EmployeeList = Object.assign({}, state.EmployeeInactiveUpdateReducer.data);
        delete state.EmployeeInactiveUpdateReducer;
        return { EmployeeList };
      case ACTION_EMPLOYEE_ACTIVE_LIST_SUCCESS:
        EmployeeActiveList = Object.assign({}, state.EmployeeInactiveUpdateReducer.data);
        delete state.EmployeeInactiveUpdateReducer;
        return { EmployeeActiveList };
      case ACTION_REIMBURSEMENT_FINANCE_APPROVAL_MANAGER_SUCCESS:
        ListReimbursementFinanceApprovalManager = Object.assign({}, state.EmployeeInactiveUpdateReducer.data);
        delete state.EmployeeInactiveUpdateReducer;
        return { ListReimbursementFinanceApprovalManager };
      case ACTION_EMPLOYEE_STATUS_SUCCESS:
        EmployeeStatus = Object.assign({}, state.EmployeeInactiveUpdateReducer.data);
        delete state.EmployeeInactiveUpdateReducer;
        return { EmployeeStatus };
      default:
    }
  }
  return {
    ReportingManager,
    EmployeeList,
    EmployeeActiveList,
    EmployeeStatus,
    ListReimbursementFinanceApprovalManager
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    actionEmployeeStatus,
    actionEmployeeGetReporting,
    actionEmployeeList,
    actionEmployeeListActive,
    actionReimbursementFinanceApprovalManager,

  }, dispatch);

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EmployeeInactiveUpdateModal));