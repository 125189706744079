import React, { useCallback, useEffect, useRef, useState } from 'react';
import ScreenTitle from '../../../../../component/screen_title';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from '../../../../../component/form/error_label';
import { FiUser } from 'react-icons/fi';
import $ from "jquery";
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { UNAUTHORIZED } from '../../../../../constants';
import { toast } from 'react-toastify';
import { ACTION_POLICY_CONTROL_NO_SUCCESS, ACTION_POLICY_TYPE_SUCCESS, ACTION_UPLOAD_POLICY_DOCUMENT_SUCCESS, actionControlPolicy, actionPolicyType, actionUploadPolicyDoc } from './action';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import Select from "react-select";
import { KEY_TOAST_MESSAGE, setData } from '../../../../../utils/preferences';


const AddPolicyUploadDocument = (props) => {

    const { register, handleSubmit, control, formState: { errors } } = useForm();


    const [controlPolicy, setControlPolicy] = useState();
    const [selectedControlNumber, setSelectedControlNumber] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileError, setFileError] = useState("")
    const [policyType, setpolicyType] = useState();


    const [options, setOptions] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")

    const [policyId, setPolicyId] = useState(null)


    // Use a ref to track the current page to avoid race conditions
    const pageRef = useRef(1)
    // Use a ref to track pages that have already been fetched
    const fetchedPagesRef = useRef(new Set([]))

    const onUploadPolicyDoc = () => {
        const { UploadDocument } = props;

        if (UploadDocument) {
            console.log("Handling upload response:", UploadDocument); // Debug line

            $("#save_insurer").prop("disabled", false);
            $("#insurer-form-loader").addClass("hidden");

            if (UploadDocument.result === true && UploadDocument.status === 200) {
                // ✅ Success case
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Policy document uploaded successfully.',
                    confirmButtonText: 'Okay',
                }).then(() => {
                    setData(KEY_TOAST_MESSAGE, "Policy document uploaded successfully.");
                    props.history.goBack();
                });

            } else {
                let errorMessage = UploadDocument.message || 'Failed to upload document. Please try again.';

                if (UploadDocument.status === UNAUTHORIZED) {
                    props.auth.logout();
                    errorMessage = 'Unauthorized access. Please login again.';
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                    confirmButtonText: 'Okay',
                });
            }
        }
    };
    useEffect(() => {
        console.log("UploadDocument response:", props.UploadDocument);
        onUploadPolicyDoc();
    }, [props.UploadDocument]);




    const onTypePolicy = () => {
        const { PolicyType } = props;

        if (PolicyType?.response) {
            console.log("Received Policy Type Data:", PolicyType.response);

            // Format options properly
            const formattedOptions = PolicyType.response.map(item => ({
                type: item.type === "policy" ? "Policy" : `${item.endo_id}`, // Display "E1", "E2", etc.
                id: item.id
            }));

            console.log("Formatted Policy Type Data:", formattedOptions);
            setpolicyType(formattedOptions);
        } else {
            console.error("Policy Type API failed:", PolicyType?.message);

            if (PolicyType?.status === UNAUTHORIZED) {
                props.auth.logout();
                toast.error("Your session expired, please login again.");
            }
        }
    };


    useEffect(onTypePolicy, [props.PolicyType]);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
            setFileError("Please upload a document.");
            setSelectedFile(null);
            return;
        }

        const allowedExtensions = ["pdf", "doc", "docx", "xls", "xlsx"];
        const fileSizeLimit = 5 * 1024 * 1024; // 5MB

        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
            setFileError("Invalid file type. Allowed: PDF, DOC, DOCX, XLS, XLSX.");
            setSelectedFile(null);
            return;
        }

        if (file.size > fileSizeLimit) {
            setFileError("File size exceeds 5MB limit.");
            setSelectedFile(null);
            return;
        }

        setFileError("");
        setSelectedFile(event.target.files[0]); // Ensure you're selecting the first file
    };


    const onSubmit = (data) => {
        const selectedPolicy = policyType.find(item => item.id === selectedControlNumber);

        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No file selected. Please upload a document.',
                confirmButtonText: 'Okay',
            });
            return;
        }

        const input = {
            id: policyId,
            type: selectedPolicy ? selectedPolicy.type : "policy",
            // control_no: selectedControlNumber
        };

        const files = [{ name: 'document', value: selectedFile }];

        // Ensure actionUploadPolicyDoc returns a Promise
        props.actionUploadPolicyDoc(input, files)

    };

    // Function to reset pagination and search state
    const resetPagination = () => {
        pageRef.current = 1
        fetchedPagesRef.current = new Set([])
        setHasMore(true)
        setOptions([]) // Clear options when resetting
    }

    // Handle search input change
    const handleSearchChange = (inputValue) => {
        setSearchTerm(inputValue);

        if (!inputValue.trim()) {
            console.log("Search input is empty");
            resetPagination();
            fetchOptions(1)
            setOptions([]); // Clear dropdown options if needed
            return;
        }

        // If user is typing, we'll wait for them to finish before searching
        if (inputValue.length > 2) {
            resetPagination();
            fetchOptions(1, inputValue);
        }
    };


    // API Call to Fetch Data
    const fetchOptions = useCallback(
        async (pageNumber, search = searchTerm) => {
            // Return early if already loading, no more data, or page already fetched
            if (loading || !hasMore || fetchedPagesRef.current.has(`${pageNumber}-${search}`)) return

            setLoading(true)

            try {
                // Mark this page+search combination as being fetched
                fetchedPagesRef.current.add(`${pageNumber}-${search}`)

                // Call your API with search parameter
                props.actionControlPolicy({
                    search: search,
                    page: pageNumber,
                    per_page: 100,
                })

                // Note: The actual data processing happens in onControlPolicy
                // which is triggered by the useEffect watching props.ControlNo
            } catch (error) {
                console.error("Error fetching options:", error)
                setLoading(false)
            }
        },
        [loading, hasMore, props, searchTerm],
    )

    // Initial API Fetch
    useEffect(() => {
        fetchOptions(1)
    }, [fetchOptions])

    // Infinite Scroll Handler
    const handleMenuScroll = useCallback(() => {
        if (!loading && hasMore) {
            // Increment the page ref
            pageRef.current += 1
            // Fetch the next page
            fetchOptions(pageRef.current)
        }
    }, [loading, hasMore, fetchOptions])

    // Process API response
    const onControlPolicy = useCallback(() => {
        const { ControlNo } = props

        if (ControlNo !== null && ControlNo !== undefined && ControlNo !== "") {
            if (ControlNo?.result === true) {
                if (ControlNo?.response?.length > 0) {
                    const formattedData = ControlNo.response.map((item) => ({
                        title: `${item.control_no}`,
                        value: item.id,
                    }))

                    // Check if there are more items to load
                    if (!ControlNo.response || ControlNo.response.length < 20) {
                        setHasMore(false)
                    }

                    // If this is page 1 and we're searching, replace options
                    // Otherwise append to existing options
                    setOptions((prevOptions) => {
                        if (pageRef.current === 1) {
                            return formattedData // Replace options for new search
                        } else {
                            return [...prevOptions, ...formattedData] // Append for pagination
                        }
                    })
                } else {
                    // No results found
                    if (pageRef.current === 1) {
                        setOptions([]) // Clear options for new search with no results
                    }
                    setHasMore(false)
                }
            } else {
                // API returned an error or no data
                setHasMore(false)
                if (pageRef.current === 1) {
                    setOptions([]) // Clear options on error for new search
                }
            }

            setLoading(false)
        }
    }, [props.ControlNo])

    // Watch for API response changes
    useEffect(() => {
        onControlPolicy()
    }, [props.ControlNo, onControlPolicy])


    return (
        <>
            <ScreenTitle title="Upload Policy Document" />
            <section>
                <div className="card" style={{ minHeight: "100vh" }}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Control Number&nbsp;<span className="danger">*</span></label>
                                                    {/* <Controller
                                                        name="control_no"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={options}
                                                                getOptionLabel={(e) => e.title} // Adjust based on API response
                                                                getOptionValue={(e) => e.value}
                                                                isLoading={loading}
                                                                onMenuScrollToBottom={handleMenuScroll} // Detect Scroll
                                                                placeholder="Select Policy Control No."
                                                                onChange={(selectedOption) => {
                                                                    field.onChange(selectedOption.value)
                                                                    props.actionPolicyType({ control_no: selectedOption.value })
                                                                }}
                                                            />
                                                        )}
                                                        rules={{
                                                            required: "Control Number is required",
                                                        }}
                                                    /> */}


                                                    <Controller
                                                        name="control_no"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={options}
                                                                getOptionLabel={(e) => e.title}
                                                                getOptionValue={(e) => e.value}
                                                                isLoading={loading}
                                                                onMenuScrollToBottom={handleMenuScroll}
                                                                placeholder="Select Policy Control No."
                                                                onInputChange={handleSearchChange}
                                                                onChange={(selectedOption) => {
                                                                    console.log('selectedOption', selectedOption)
                                                                    field.onChange(selectedOption)
                                                                    setSelectedControlNumber(selectedOption.title)
                                                                    props.actionPolicyType({ control_no: selectedOption.title })
                                                                    // setPolicyId(selectedOption?.value)
                                                                }}

                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        borderTop: "none",
                                                                        borderLeft: "none",
                                                                        borderRight: "none",
                                                                        borderRadius: "0px", // Optional: Ensures no rounded corners
                                                                        boxShadow: "none", // Removes default focus outline
                                                                    }),
                                                                }}

                                                            />
                                                        )}
                                                        rules={{
                                                            required: "Control Number is required",
                                                        }}
                                                    />

                                                    {errors.control_no && <ErrorLabel message={errors.control_no?.message} />}
                                                </div>
                                            </div>
                                            {selectedControlNumber && policyType?.length > 0 && (
                                                <div className="col-12 col-md-6 col-lg-6" id="customer-field">
                                                    <div className="form-group">
                                                        <label className="label-control">Type&nbsp;<span className="danger">*</span></label>
                                                        <Controller
                                                            name="policy_type"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={policyType}
                                                                    fields={{ text: "type", value: "id" }}
                                                                    placeholder="Select Policy Type"
                                                                    // change={(event) => field.onChange(event.itemData?.id)}
                                                                    change={(event) => {
                                                                        console.log('selectedOption', event)
                                                                        field.onChange(event)
                                                                     
                                                                        setPolicyId(event.itemData?.id)
                                                                    }}
                                                                />
                                                            )}
                                                            rules={{ required: "Policy Type is required" }}
                                                        />

                                                        {errors.policy_type && <ErrorLabel message={errors.policy_type?.message} />}
                                                    </div>
                                                </div>
                                            )}

                                            {/* File Upload */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Upload Document&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        accept=".pdf,.doc,.docx,.xls,.xlsx"
                                                        onChange={handleFileChange}
                                                    />
                                                    {fileError && <ErrorLabel message={fileError} />}
                                                </div>
                                            </div>



                                            {/* Submit Button */}
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-primary">
                                                    Upload Document
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
        </>
    )
}

const mapStateToProps = (state) => {

    var ControlNo = null;
    var PolicyType = null;
    var UploadDocument = null;


    if (
        state.UploadPolicyDocumentReducer != null &&
        Object.keys(state.UploadPolicyDocumentReducer).length > 0 &&
        state.UploadPolicyDocumentReducer.constructor === Object
    ) {
        switch (state.UploadPolicyDocumentReducer.tag) {
            case ACTION_POLICY_CONTROL_NO_SUCCESS:
                ControlNo = Object.assign({}, state.UploadPolicyDocumentReducer.data);
                delete state.UploadPolicyDocumentReducer;
                return { ControlNo };
            case ACTION_POLICY_TYPE_SUCCESS:
                PolicyType = Object.assign({}, state.UploadPolicyDocumentReducer.data);
                delete state.UploadPolicyDocumentReducer;
                return { PolicyType };
            case ACTION_UPLOAD_POLICY_DOCUMENT_SUCCESS:
                UploadDocument = Object.assign({}, state.UploadPolicyDocumentReducer.data);
                delete state.UploadPolicyDocumentReducer;
                return { UploadDocument };


            default:
        }
    }
    return {
        ControlNo,
        PolicyType,
        UploadDocument
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionControlPolicy,
        actionPolicyType,
        actionUploadPolicyDoc
    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AddPolicyUploadDocument))

